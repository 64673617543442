import { axiosQip as axios } from "../services/axios";

// TODO: change mapping when api is updated
export async function get(): Promise<{
  admin: number;
  member: number;
  total: number;
}> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get("/api/service", config);

  return {
    admin: response.data["Total Admin Users: "],
    member: response.data["Total Member Users: "],
    total:
      response.data["Total Admin Users: "] +
      response.data["Total Member Users: "],
  };
}

export type Center = {
  id: number;
  name: string;
};

export async function getCenters(): Promise<Center[]> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get("/api/centers", config);
  return response?.data;
}

export const serviceApi = {
  get,
  getCenters,
};
