import { debounce } from "lodash";
import { FaCheckCircle, FaSearch, FaSpinner, FaTrashAlt } from "react-icons/fa";
import { Dropdown } from "semantic-ui-react";

type FiltersProps = {
  setToggleSearch(): void;
  active: boolean;
  setActive: () => void;
  deleted: boolean;
  setDeleted: () => void;
  deletedCount: number;
  filterSelection: string;
  serviceFilter: string;
  subTypeFilter: string;
  setFilterSelection: (val: string) => void;
  currentPage: number;
  productList: [];
  setCurrentPage: (page: number) => void;
  setServiceFilter: (val: string) => void;
  setSubTypeFilter: (val: string) => void;
  isLoading: boolean;
};

const Filters = (props: FiltersProps) => {
  const onSearch = debounce(() => props.setToggleSearch(), 400);

  return (
    <div className="flex justify-between">
      <div className="flex">
        <button
          className={`btnFilter mr-2 ${props.active ? "text-hubGreen" : ""}`}
          onClick={props.setActive}
        >
          <FaCheckCircle size={12} className="mr-1" />
          Active
        </button>
        <button
          className={`btnFilter ${props.deleted ? "text-hubRed" : ""}`}
          onClick={props.setDeleted}
        >
          <FaTrashAlt size={12} className="mr-1" />
          Deleted ({props.deletedCount})
        </button>
        <div className="flex border-l border-hubGrayLt p-1">
            <div className="flex justify-center items-center px-2">
              <Dropdown
                value={props.subTypeFilter ? props.subTypeFilter : "All"}
                options={props.productList}
                onChange={(e, { value }) => {
                  props.setSubTypeFilter(String(value));
                  onSearch();
                }}
              />
            </div>
            
          </div>
      </div>
      <div className="flex">
        <div className="flex border border-hubGrayLt">
          <input
            className="outline-none px-2"
            type="text"
            value={props.serviceFilter}
            placeholder="Find admin"
            onChange={(e) => {
              if (props.currentPage !== 1) {
                props.setCurrentPage(1);
              }
              props.setServiceFilter(e.target.value);
              onSearch();
            }}
          />

          {/* <FaSpinner
            className={`${
              props.isLoading ? "visible mt-4 mr-2 animate-spin" : "invisible"
            }  `}
          /> */}

          <div className="flex border-l border-hubGrayLt p-1">
            <div className="flex justify-center items-center px-2">
              <Dropdown
                value={props.filterSelection}
                options={[
                  { text: "Service", value: "service" },
                  { text: "Email", value: "email" },
                  { text: "Subscription", value: "subscription" },
                ]}
                onChange={(e, { value }) => {
                  console.log(value)
                  props.setFilterSelection(String(value));
                }}
              />
            </div>
            <div className="bg-hubGreen text-white flex justify-center items-center px-2.5">
              <FaSearch />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
