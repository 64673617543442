import { useParams } from "react-router";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";

export enum SUBPAGES {
  view = "view",
  create = "create",
  edit = "edit",
}

const Users = () => {
  const pageParams = useParams<{ page: string }>();
  const activePage = pageParams.page || SUBPAGES.view;

  return (
    (activePage === SUBPAGES.create && <Create />) ||
    (activePage === SUBPAGES.edit && <Edit />) || <View />
  );
};
export default Users;
