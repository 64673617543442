import React from "react";
import BaseModal from "../../BaseModal";
import styles from "./Confirm.module.css";

type ConfirmProps = {
  text?: string;
  isOpen: boolean;
  confirmText?: string;
  declineText?: string;
  onConfirm?: () => void;
  onDecline?: () => void;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen";
  actionBtnsClass?: string;
};

export default function Confirm(props: ConfirmProps) {
  return (
    <BaseModal isOpen={props.isOpen} onClose={props.onClose} size={props.size}>
      <div className={`flex flex-col items-center mb-8 mx-8 mr-12`}>
        {props.text && (
          <p className={`${styles.textPrompt} mb-8 mt-1`}>{props.text}</p>
        )}
        {props.children}
        <div
          className={`${styles.actionBtns} ${
            props.actionBtnsClass ? styles[props.actionBtnsClass] : ""
          }`}
        >
          {props.onConfirm && (
            <button className={styles.confirmBtn} onClick={props.onConfirm}>
              {props.confirmText || "Yes"}
            </button>
          )}
          {props.onDecline && (
            <button className={styles.declineBtn} onClick={props.onDecline}>
              {props.declineText || "No"}
            </button>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
