import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FaArrowLeft, FaEnvelope } from "react-icons/fa";
import styles from "./Messages.module.css";
import { Message } from "../../api/messages";

type MsgState = {
  message: Message;
};

const ViewMsg = () => {
  const location = useLocation<MsgState>();
  const history = useHistory();

  const message = location.state?.message;

  if (!message) return <Redirect to="/messages" />;

  return (
    <div
      className={`${styles.mainContainer} flex-1  px-2 mt-10 md:mb-28 lg:mb-10`}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className={styles.headerTextIcon}>
            <FaEnvelope size={55} />
          </div>
          <div className={`${styles.headerText} ml-4`}>
            <h1>Messages</h1>
            <p>View and delete messages sent to users across products</p>
          </div>
        </div>
        <div className="flex items-center">
          <button
            className="flex items-center p-5 text-lg font-medium rounded
             bg-white bg-opacity-80 text-hubBlack text-opacity-20
              hover:bg-opacity-100 hover:text-opacity-50"
            onClick={() => history.push("/messages")}
          >
            <FaArrowLeft className="mr-2" />
            Back to messages
          </button>
        </div>
      </div>
      <div className={`${styles.container} mt-10`}>
        <div className="bg-hubBlueLt p-5 text-white text-reg rounded-t">
          <span className="mr-4">{`Sent on ${message.date
            .split("-")
            .join("/")}`}</span>
          |<span className="ml-4">{`Sent by ${message.sender}`}</span>
        </div>
        <div className={`${styles.container}`}>
          <div className="lg:px-14 lg:py-12 px-10 py-8">
            <p className="text-2xl">{message.subject}</p>
            <div className="flex gap-3 mt-5">
              {message.user_to ? (
                <div className="text-hubGreen bg-hubGreen bg-opacity-20 text-lg px-5 py-3 rounded">
                  {message.user_to}
                </div>
              ) : message.sent_to?.length ? (
                message.sent_to.map((m, i) => (
                  <div
                    key={i}
                    className="text-hubGreen bg-hubGreen bg-opacity-20 text-lg px-5 py-3 rounded"
                  >
                    {m}
                  </div>
                ))
              ) : message.send_to?.length ? (
                message.send_to.map((m, i) => (
                  <div
                    key={i}
                    className="text-hubGreen bg-hubGreen bg-opacity-20 text-lg px-5 py-3 rounded"
                  >
                    {m}
                  </div>
                ))
              ) : null}
            </div>
          </div>
          {message?.content && (
            <div className="border-t">
              <div
                className={`disabledPreflight md:px-7 lg:px-14 py-8`}
                dangerouslySetInnerHTML={{ __html: message.content }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="btnGreen px-10 py-6 text-lg my-10"
          onClick={() => history.push("/messages")}
        >
          BACK TO MESSAGES
        </button>
      </div>
    </div>
  );
};

export default ViewMsg;
