import { useState } from "react";
import { FaCommentDots, FaEdit, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router";
import Pagination from "../../components/Pagination";
import Confirm from "../../components/Popups/Confirm";
import styles from "./Users.module.css";

const View = () => {
  const history = useHistory();

  const [toRemove, setToRemove] = useState(false);
  const [successRemove, setSuccessRemove] = useState(false);

  const users = [
    { name: "Alina Dan", isAdmin: true },
    { name: "Alina Dan" },
    { name: "Alina Dan" },
    { name: "Alina Dan" },
    { name: "Alina Dan" },
    { name: "Alina Dan" },
    { name: "Alina Dan" },
  ];

  const goToCreatePage = () => history.push("/users/create");

  return (
    <>
      {toRemove && (
        <Confirm
          isOpen={!!toRemove}
          size="tiny"
          text="Are you sure you want to remove user?"
          onConfirm={() => {
            setToRemove(false);
            setSuccessRemove(true);
          }}
          onDecline={() => setToRemove(false)}
          onClose={() => setToRemove(false)}
        />
      )}
      {successRemove && (
        <Confirm
          isOpen={successRemove}
          size="tiny"
          text="This user in now removed"
          onClose={() => setSuccessRemove(false)}
        >
          <button
            className="btnBlueLt px-16 py-4 text-lg"
            onClick={() => setSuccessRemove(false)}
          >
            Ok
          </button>
        </Confirm>
      )}
      <div
        className={`${styles.mainContainer} flex-1 px-2 mt-10 md:mb-28 lg:mb-10`}
      >
        <div className="flex">
          <div className={styles.headerTextIcon}>
            <FaCommentDots size={55} />
          </div>
          <div className={`${styles.headerText} ml-4`}>
            <h1>User Accounts</h1>
            <p>Update and manage your HM&C Admin Users</p>
          </div>
        </div>
        <div className={`${styles.rowHolder} py-10`}>
          <div className="flex justify-end">
            <button
              className="btnGreenLt px-7 text-reg py-4 rounded-md font-medium"
              onClick={goToCreatePage}
            >
              Add new user
            </button>
          </div>
          {users.map((u, key) => (
            <div key={key} className="flex justify-between items-center">
              <p className="ml-4">
                {u.name}
                {u.isAdmin && (
                  <span className={`${styles.orangeTag} ml-3`}>
                    Super admin
                  </span>
                )}
              </p>
              <div className={`${styles.actionBtns} flex gap-x-2`}>
                <button
                  className={styles.editBtn}
                  onClick={() => history.push("/users/edit")}
                >
                  <FaEdit className="mr-3" />
                  Edit
                </button>
                {!u.isAdmin && (
                  <button
                    className={styles.removeBtn}
                    onClick={() => setToRemove(true)}
                  >
                    <FaTimes className="mr-3" />
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Pagination
                currentPage={2}
                lastPage={3}
                onPageChange={(page) => console.log({ page })}
              />
            </div>
            <div>
              <button
                className="btnGreenLt px-7 text-reg h-full rounded-md font-medium"
                onClick={goToCreatePage}
              >
                Add new user
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
