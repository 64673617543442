import authStore, { AuthModel } from "./authModel";

export interface StoreModel {
  authStore: AuthModel;
}

const storeModel: StoreModel = {
  authStore,
};

export default storeModel;
