type SkeletonProps = {
  className?: string;
};

const Skeleton = ({ className }: SkeletonProps) => {
  return (
    <div className={`animate-pulse space-x-4 ${className || "w-full h-10"}`}>
      <div className={`bg-hubGrayLt rounded  h-full`}></div>
    </div>
  );
};

export default Skeleton;
