import styles from "./LoginPage.module.css";
import login_banner from "../../static/images/login_banner.png";
import logo from "../../static/images/transparent_logo.png";
import {
  FaArrowAltCircleRight,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaSpinner,
} from "react-icons/fa";
import { login } from "../../api/request";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { setUserToken } from "../../services/auth";
import { useStoreActions, useStoreState } from "../../hooks";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsChecked, setFieldsChecked] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const setAuthUser = useStoreActions(
    (actions) => actions.authStore.setAuthUser
  );

  const onLogin = async () => {
    setFieldsChecked(true);
    if (
      !email ||
      !password! ||
      (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    ) {
      return;
    }

    try {
      setLoading(true);

      const response = await login({
        username: email,
        password,
      });

      if (response.status) {
        if (response.auth_user.user_type.toLowerCase() === "super_admin") {
          setAuthUser(response.auth_user);
          setUserToken(response.access_token, window.location.reload());
        } else {
          localStorage.clear();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center my-auto mx-auto py-4">
      <div className={`${styles.container} shadow-md`}>
        <div className="bg-white px-14 py-16 flex flex-col gap-y-8">
          <p className="text-xl mb-5">Administrator login</p>
          <div className="flex flex-col gap-y-4">
            <label>Your username</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="outline-none border border-hubGrayLt p-4 rounded"
              type="text"
              placeholder="Username"
            />
            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {fieldsChecked &&
                email &&
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && (
                  <> Please provide a valid email address. </>
                )}
              {fieldsChecked && !email && <> This field is required. </>}
            </div>
          </div>
          <div className="flex flex-col gap-y-2 ">
            <label>Password</label>
            <div className="relative flex items-center">
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="outline-none border border-hubGrayLt p-4 rounded flex-1 pr-12"
                type={showPassword ? `text` : `password`}
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={`toggleShowPassword cursor-pointer pr-2 absolute right-2`}
              >
                {showPassword ? (
                  <FaEyeSlash className={`text-xl`} />
                ) : (
                  <FaEye className={`text-xl`} />
                )}
              </div>
            </div>
            <div className={`text-red-400 italic py-1 px-1 h-4`}>
              {fieldsChecked && !password && <> This field is required. </>}
            </div>
          </div>
          <div>
            <button onClick={onLogin} disabled={loading} className="btnGreen">
              {loading ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />
                  <span className={`ml-2`}>Please wait...</span>
                </div>
              ) : (
                <>
                  <FaArrowAltCircleRight className="mr-2" />
                  LOGIN NOW{" "}
                </>
              )}
            </button>
          </div>
          <div className="text-hubRed flex items-center">
            <FaInfoCircle className="mr-3" size={26} />
            <a href="#" className="underline hover:text-hubRed">
              I forgot my password
            </a>
          </div>
        </div>
        <div
          className="bg-center bg-cover bg-no-repeat flex justify-center items-center"
          style={{ backgroundImage: `url(${login_banner})` }}
        >
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Login;
