import React from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

type PaginationProps = {
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
};

const Pagination = ({
  currentPage,
  lastPage,
  onPageChange,
}: PaginationProps) => {
  const isFirstPage = () => {
    return currentPage === 1;
  };

  const isLastPage = () => {
    return currentPage === lastPage;
  };

  const dspBtns = () => {
    const n = lastPage;
    const i = currentPage;

    if (n <= 9)
      return ((n) => {
        const arr = Array(n);
        while (n) {
          arr[n - 1] = n--;
        }
        return arr;
      })(n);
    if (i <= 5) return [1, 2, 3, 4, 5, 6, 7, 0, n]; // 0 represents `···`
    if (i >= n - 4) return [1, 0, n - 6, n - 5, n - 4, n - 3, n - 2, n - 1, n];
    return [1, 0, i - 2, i - 1, i, i + 1, i + 2, 0, n];
  };

  const handleClick = (n: number) => {
    onPageChange(n);
  };

  const turnPage = (page: number) => {
    onPageChange(page);
  };
  return (
    <>
      {!isFirstPage() && (
        <button
          onClick={() => turnPage(currentPage - 1)}
          className="px-5 py-4 rounded bg-hubGrayLt3 text-hubBlack border border-hubGrayLt hover:bg-hubGrayLt2"
        >
          <FaAngleDoubleLeft />
        </button>
      )}
      {dspBtns().map((page, i) => {
        return (
          <React.Fragment key={i}>
            {page !== 0 && (
              <button
                key={i}
                className={`${
                  currentPage === page
                    ? "bg-hubBlueLt text-white"
                    : "bg-hubGrayLt3 text-hubBlack border border-hubGrayLt hover:bg-hubGrayLt2"
                } px-6 py-4 rounded`}
                onClick={() => {
                  handleClick(page);
                }}
              >
                {page}
              </button>
            )}
            {page === 0 && (
              <button
                key={i}
                className="px-6 py-4 rounded bg-hubGrayLt3 text-hubBlack border border-hubGrayLt hover:bg-hubGrayLt2"
              >
                {`...`}
              </button>
            )}
          </React.Fragment>
        );
      })}
      {!isLastPage() && (
        <button
          onClick={() => turnPage(currentPage + 1)}
          className="px-5 py-4 rounded bg-hubGrayLt3 text-hubBlack border border-hubGrayLt hover:bg-hubGrayLt2"
        >
          <FaAngleDoubleRight />
        </button>
      )}
    </>
  );
};

export default Pagination;
