import generateRandom from "../utils/generateRandom";

const redirect = (userId: number, centerId: number, stripeId: string) => {
  const hostname = new URL(window.location.href).hostname.split(".");

  let token = localStorage.getItem("hubAdminToken");

  // const sub = new URL(process.env.REACT_APP_QIP_URL || "").hostname.split(
  //   "."
  // )[0];

  let appURL = process.env.REACT_APP_QIP_URL;

  if (hostname[0] === "localhost") {
    window.open(
      "http://localhost:3001/view-service-dashboard?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        stripeId +
        "&accessToken=" +
        token +
        "&counter=" +
        generateRandom(12)
    );
  } else {
    // const host = hostname.slice(1, hostname.length).join(".");
    window.open(
      appURL +
        "/view-service-dashboard?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        stripeId +
        "&accessToken=" +
        token +
        "&counter=" +
        generateRandom(12)
    );
  }
};

export default redirect;
