const Footer = () => {
  return (
    <footer className="bg-black">
      <p className="text-center text-white text-opacity-60 p-9">
        Copyright © 2021 Holistic Management And Consultancy. All Rights
        Reserved
      </p>
    </footer>
  );
};

export default Footer;
