import { axiosHub as axios } from "../services/axios";
import { ICenterUser, ILoginCredentials } from "../types";

export async function login(loginPayload: ILoginCredentials) {
  const payload = {
    username: loginPayload.username,
    password: loginPayload.password,
    grant_type: "password",
    client_id: 8,
    client_secret: "LrCrm649CSz0RWPr3sdCwkxm337o09TXTwesqgBE",
  };
  try {
    const response = await axios.post(`oauth/token`, payload);

    let config = {
      headers: {
        Authorization: "Bearer " + response.data.access_token,
      },
    };

    const response2 = await axios.get("/api/user", config);

    return {
      status: true,
      access_token: response.data.access_token,
      auth_user: response2.data,
    };
  } catch (error: any) {
    return {
      status: false,
      ...error.toJSON(),
    };
  }
}

export async function fetchCenterUsers(
  centerId: number
): Promise<ICenterUser[] | []> {
  try {
    // let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

    const QIP_API_URL = process.env.REACT_APP_QIP_API_URL;

    let token: any = localStorage.getItem("hubAdminToken");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await axios.get(
      `${QIP_API_URL}/api/center/${centerId}/users`,
      config
    );

    return response.data ? (response.data as ICenterUser[]) : [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAppUsageLog(userId: number) {
  const QIP_API_URL = process.env.REACT_APP_QIP_API_URL;

  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(
    `${QIP_API_URL}/api/usagelog/${userId}`,
    config
  );
  console.log("usage logs ", response.data);
  // let appLogs: any = [];

  if (response.data) {
    // response.data.forEach((data: any) => {
    //   let centerInfo = {
    //     centerName: data.center_name,
    //     centerId: data.center_id,
    //     hasQip: data.has_qip,
    //     hasPortal: data.has_portal,
    //   };
    //   data.center_users.forEach((user: any) => {
    //     centerInfo = {
    //       ...centerInfo,
    //       ...{
    //         id: user.id,
    //         name: user.name,
    //         userId: user.user_id,
    //         type: user.type,
    //         qipLastLogin: user.last_login_qip,
    //         portalLastLogin: user.last_login_portal,
    //       },
    //     };

    //     appLogs = [...appLogs, centerInfo];
    //   });
    // });

    return {
      qipAppLogs: response.data.qip_logs,
      portalAppLogs: response.data.portal_logs,
      // qipAppLogs: appLogs,
      // portalAppLogs: appLogs,
    };
  }

  return {
    qipAppLogs: [],
    portalAppLogs: [],
  };
}
