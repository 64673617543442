import { ImSpinner8 } from "react-icons/im";

type SpinnerProps = {
  text?: string;
  size?: number;
};

const Spinner = (props: SpinnerProps) => {
  return (
    <div className="flex items-center justify-center flex-col">
      <ImSpinner8
        size={props.size || 28}
        style={{ color: "#7BAE3E" }}
        className="animate-spin"
      />
      <span className="mt-1 ml-2 text-gray-400">{props.text || "Loading..."}</span>
    </div>
  );
};

export default Spinner;
