import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaCaretDown,
  FaExternalLinkSquareAlt,
  FaSignInAlt,
} from "react-icons/fa";
import styles from "./Header.module.css";
import hub_logo from "../../static/images/hub_logo.png";
import {
  isUserLoggedIn,
  userLogout,
  getUserLoggedIn,
} from "../../services/auth";
import { redirect as redirectToAdminPortal } from "../../helpers/redirectToAdminPortal";
import { redirect as redirectToAdminQip } from "../../helpers/redirectToAdminQip";

const Header = () => {
  const isLoggedIn = isUserLoggedIn();
  const authUser = getUserLoggedIn();
  const location = useLocation();
  console.log(location.pathname);

  const onRedirectToAdminPortal = () => {
    redirectToAdminPortal(authUser);
  };

  const onRedirectToAdminQip = () => {
    redirectToAdminQip(authUser.id, authUser.stripe_id || "empty");
  };

  return (
    <header className={styles.header}>
      <div
        className={`${styles.row} ${isLoggedIn ? "" : "paddingHeaderNoAuth"}`}
      >
        <div className={styles.logo}>
          <Link to="/">
            <img src={hub_logo} alt="hub logo" />{" "}
          </Link>
        </div>
        {isLoggedIn && (
          <div className={styles.menu}>
            <div>
              <ul className={styles.menuList}>
                <li>
                  <Link
                    to="/subscription"
                    className={location.pathname === "/" ? styles.active : ""}
                  >
                    SUBSCRIPTIONS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/messages"
                    className={
                      location.pathname === "/messages" ||
                      location.pathname === "/messages/compose" ||
                      location.pathname === "/users/draft"
                        ? styles.active
                        : ""
                    }
                  >
                    MESSAGES
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/users"
                    className={
                      location.pathname === "/users" ||
                      location.pathname === "/users/create" ||
                      location.pathname === "/users/edit"
                        ? styles.active
                        : ""
                    }
                  >
                    ADMIN USERS
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://holisticmanagement.net.au/get-help/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HELP
                  </a>
                </li>
                <li>
                  <a href="#" className="flex justify-center items-start">
                    <span className="mr-1">LAUNCH</span>
                    <FaCaretDown size={17} />
                  </a>
                  <ul className={styles.subMenu}>
                    <li>
                      <a
                        className={`cursor-pointer`}
                        onClick={() => onRedirectToAdminQip()}
                      >
                        <span className={`flex justify-center items-center`}>
                          <span className={`mr-1`}>HOLISTIC QIP</span>
                          <FaExternalLinkSquareAlt size={15} />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="cursor-pointer"
                        onClick={() => onRedirectToAdminPortal()}
                      >
                        <span className="flex justify-center items-center">
                          <span className="mr-1">HMC Portal</span>
                          <FaExternalLinkSquareAlt size={15} />
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className={styles.buttons}>
              <a
                className={`btnGreen hover:text-white ${
                  !isLoggedIn ? "hidden" : ""
                }`}
                onClick={(e) => {
                  userLogout(window.location.assign("/"));
                }}
              >
                <FaSignInAlt size={17} className="mr-2" />
                SIGN OUT
              </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
