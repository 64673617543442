export function pricing() {
  if (process.env.REACT_APP_PRICEID === "TEST") {
    return test_pricing;
  } else if (process.env.REACT_APP_PRICEID === "LIVE") {
    return live_pricing;
  } else {
    return test_pricing;
  }
}

export const test_pricing = [
  {
    product: "Holistic QIP",
    price_id: "price_1ISv7XDeO3XG77wNZHPAFVGy",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1ISv7HDeO3XG77wNIKR5IAk3",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1HjdVfDeO3XG77wNA9yXwT1m",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1HjdVfDeO3XG77wNweTXFOcB",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1ISv8XDeO3XG77wNAPhSKSsG",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1ISv8MDeO3XG77wNfKE2mu9G",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1Je9nQDeO3XG77wNUENKkNku",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic Management Portal",
    price_id: "price_1Je8eqDeO3XG77wNMUrEtNZh",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Je8eqDeO3XG77wNNhU7Kkgz",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Je8eqDeO3XG77wNLAJuQZdN",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Je8eqDeO3XG77wNUyTyiWJE",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Je8giDeO3XG77wNHqVRZctE",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Je8giDeO3XG77wNBcdVvY36",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Je8giDeO3XG77wNbKQ0QJi6",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Je8giDeO3XG77wNGOUjukdy",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Je9jLDeO3XG77wNX6uyKkNN",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Je9jLDeO3XG77wNA2L2k9iw",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Je9jLDeO3XG77wNIVzhVRi4",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Je9jLDeO3XG77wNvasJuWzQ",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Je9ljDeO3XG77wNkCiEIbSu",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Je9ljDeO3XG77wN3Fwcc8xi",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Je9ljDeO3XG77wNzrowg6XJ",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Je9ljDeO3XG77wNZU8IjcDy",
    interval: "month",
    withTrial: false,
  },
];

export const live_pricing = [
  {
    product: "Holistic QIP",
    price_id: "price_1ISmBJDeO3XG77wNESEFUbyJ",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1ISmAZDeO3XG77wNRRCmGou1",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1IOWbPDeO3XG77wNBFPuWdQR",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP",
    price_id: "price_1IOWbPDeO3XG77wNEHMAsNwB",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1ISmCKDeO3XG77wNbv89lSUH",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1ISmByDeO3XG77wNxXqNbNWM",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1IOWb0DeO3XG77wN3N58kPjh",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP + Mentoring",
    price_id: "price_1IOWb0DeO3XG77wNEhS9fx7l",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic Management Portal",
    price_id: "price_1Js2AVDeO3XG77wNt1D2af06",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Js2AWDeO3XG77wNL7wfZraE",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Js2AVDeO3XG77wN9yJ72jGI",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic Management Portal",
    price_id: "price_1Js2AWDeO3XG77wNEUCRDJvw",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Js29ZDeO3XG77wNvXsMfMI2",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Js29ZDeO3XG77wNXsQ7eG1I",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Js29ZDeO3XG77wNxoLECEMT",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic Management Portal + Mentoring",
    price_id: "price_1Js29ZDeO3XG77wNAy1uRmrR",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Js28ODeO3XG77wNAAbNFRyG",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Js28ODeO3XG77wNl9BsLklo",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Js28ODeO3XG77wNL3gOVFVC",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP & Holistic Management Portal",
    price_id: "price_1Js28PDeO3XG77wNl2illxSF",
    interval: "month",
    withTrial: false,
  },

  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Js279DeO3XG77wNG6vlBF2N",
    interval: "year",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Js279DeO3XG77wN5FOcYcHc",
    interval: "month",
    withTrial: true,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Js279DeO3XG77wNAcxg6LOb",
    interval: "year",
    withTrial: false,
  },
  {
    product: "Holistic QIP & Management Portal + Mentoring",
    price_id: "price_1Js279DeO3XG77wNZb0f9A8f",
    interval: "month",
    withTrial: false,
  },
];
