import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Login = lazy(() => import("../../pages/LoginPage"));

interface LoginPageProps {
  route: typeof Routes.LOGINPAGE;
  path: string;
  exact: boolean;
}

const LoginPageRoute: React.FC<LoginPageProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Login} />
  );
};

export default LoginPageRoute;
