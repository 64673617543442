import { FaArrowCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router";

const Back = () => {
  const history = useHistory();

  return (
    <button
      onClick={history.goBack}
      className="flex bg-white bg-opacity-80 hover:bg-opacity-100 items-center font-reg text-hubBlack text-opacity-20 hover:text-opacity-100 py-4 px-5 rounded font-medium"
    >
      <FaArrowCircleLeft className="mr-2" />
      Back
    </button>
  );
};

export default Back;
