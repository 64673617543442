import { useQuery } from "react-query";
import { messagesApi } from "../api/messages";
import { STALE_TIME } from "../services/query";

const useFetchMsgUsers = (enabled = false) => {
  return useQuery(["msgUsers"], () => messagesApi.getAllUsers(), {
    enabled,
    staleTime: STALE_TIME.HIGH,
  });
};

export default useFetchMsgUsers;
