import swal, { SweetAlertIcon } from "sweetalert2";

export const alertMessage = (type: SweetAlertIcon, message: string) => {
  return swal.fire({
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
};
