import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import { isUserLoggedIn } from "./services/auth";

const App = () => {

  return (
    <Router basename={process.env.BASENAME}>
      <Switch>
        <Route
          path={`/`}
          render={() => {
            return isUserLoggedIn() ? <LoggedInPages /> : <LoggedOutPages />;
          }}
        ></Route>
      </Switch>
    </Router>
  );
};

export default App;
