import React from "react";
import { Route } from "react-router-dom";
import Layout from "../Layout";
import { RoutesType } from "../../context/App";

interface RouterProps {
  Component: React.ElementType;
  exact: boolean;
  route: RoutesType;
  path: string;
}

const PrivateRoute = ({ Component, exact, route, path }: RouterProps) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return <Layout route={route} Children={Component} {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
