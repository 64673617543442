import Skeleton from "../../../../components/Skeleton";
import styles from "./Summary.module.css";

type SummaryProps = {
  isLoadingSubscribers: boolean;
  isLoadingCount: boolean;
  isLoadingRevenue: boolean;
  totalSubscribers?: number;
  payingSubscribers?: number;
  trialSubscribers?: number;
  qipUsers?: number;
  portalUsers?: number;
  totalUsers?: number;
  revenueMonthly?: number;
  revenueAnnual?: number;
};

const Count = (props: { count?: number; label: string }) => {
  return (
    <div className={`${styles.value}`}>
      <p className="text-right mr-4 w-1/5">{props.count || 0}</p>
      <p className="w-4/5">{props.label}</p>
    </div>
  );
};

const Amount = (props: { amount?: string; label: string }) => {
  return (
    <div className={`${styles.value} flex-wrap`}>
      <p className="text-right mr-4">${props.amount || "0"}</p>
      <p className="">{props.label}</p>
    </div>
  );
};

const Loader = () => {
  return (
    <div className="flex gap-5 justify-center">
      <Skeleton className="h-9 w-1/6" />
      <Skeleton className="h-9 w-4/6" />
    </div>
  );
};

const Summary = (props: SummaryProps) => {
  return (
    <div className="flex flex-col">
      <div className={styles.heading}>
        <h3>SUMMARY OF TOTAL SUBSCRIBERS</h3>
        <p className="my-2">
          View a financial summary of all Holistic Products.
        </p>
      </div>
      <div className="mt-5 gap-4 grid md:grid-cols-2 lg:grid-cols-3">
        <div
          className={`${styles.summary} ${
            props.isLoadingSubscribers ? "gap-y-3" : ""
          }`}
        >
          {props.isLoadingSubscribers ? (
            <>
              <Loader />
              <Loader />
              <Loader />
            </>
          ) : (
            <>
              <Count count={props.totalSubscribers} label="Total subscribers" />
              <Count
                count={props.payingSubscribers}
                label="Paying subscribers"
              />
              <Count count={props.trialSubscribers} label="Trial subscribers" />
            </>
          )}
        </div>
        <div
          className={`${styles.summary} ${
            props.isLoadingCount ? "gap-y-3" : ""
          }`}
        >
          {props.isLoadingCount ? (
            <>
              <Loader />
              <Loader />
              <Loader />
            </>
          ) : (
            <>
              <Count count={props.qipUsers} label="Holistic QIP users" />
              <Count count={props.portalUsers} label="HMC Portal users" />
              <Count count={props.totalUsers} label="Total users" />
            </>
          )}
        </div>
        <div
          className={`${styles.summary} ${
            props.isLoadingSubscribers ? "gap-y-3" : ""
          }`}
        >
          {props.isLoadingSubscribers ? (
            <>
              <Loader />
              <Loader />
            </>
          ) : (
            <>
              <Amount
                amount={`${props.revenueMonthly}`}
                label="Monthly revenue"
              />
              <Amount
                amount={`${props.revenueAnnual}`}
                label="Annual revenue"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
