import React from "react";
import { FaTimes } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import styles from "./BaseModal.module.css";

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen";
}

const BaseModal = (props: ModalProps) => {
  return (
    <Modal
      centered
      open={props.isOpen}
      onClose={props.onClose}
      size={props.size}
    >
      <div>
        <div className={styles.modalHeader}>
          <button onClick={props.onClose} className={styles.close}>
            <FaTimes size={16} />
          </button>
        </div>
        <>{props.children}</>
      </div>
    </Modal>
  );
};

export default BaseModal;
