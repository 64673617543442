import axios from "axios";
import { isUserLoggedIn } from "./auth";
// TODO: auth header config

const axiosAuthCheckInstance = axios.create();

axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

const axiosQip = axios.create({
  baseURL: process.env.REACT_APP_QIP_API_URL,
});

axiosQip.interceptors.request.use(
  async (config) => {
    const accessToken = isUserLoggedIn();

    if (accessToken) {
      const getAuthcheck = async () => {
        let config = {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        };
        try {
          const response = await axiosAuthCheckInstance.get(
            `${process.env.REACT_APP_API_URL}api/auth-check`,
            config
          );

          return response.data.valid;
        } catch (error) {
          console.error(error);

          return false;
        }
      };

      const status = await getAuthcheck();

      if (status) {
        return config;
      } else {
        localStorage.clear();
        window.location.reload();
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosQip.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

const axiosHub = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosHub.interceptors.request.use(
  async (config) => {
    const accessToken = isUserLoggedIn();

    if (accessToken) {
      const getAuthcheck = async () => {
        let config = {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        };
        try {
          const response = await axiosAuthCheckInstance.get(
            `${process.env.REACT_APP_API_URL}api/auth-check`,
            config
          );

          return response.data.valid;
        } catch (error) {
          console.error(error);

          return false;
        }
      };

      const status = await getAuthcheck();

      if (status) {
        return config;
      } else {
        localStorage.clear();
        window.location.reload();
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosHub.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export { axiosQip, axiosHub };
