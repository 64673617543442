import { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import Confirm from "../Confirm";
import styles from "./ChooseUsers.module.css";
import useFetchMsgUsers from "../../../hooks/useFetchMsgUsers";

type ChooseUsersProps = {
  isOpen: boolean;
  selectedIds?: number[];
  onClose?: () => void;
  onSave: (selected: number[]) => void;
};

export default function ChooseUsers(props: ChooseUsersProps) {
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState(props.selectedIds || []);
  const { data: users, isLoading } = useFetchMsgUsers(props.isOpen);

  useEffect(() => {
    if (!props.isOpen) return;

    setSelected(props.selectedIds || []);
  }, [props.isOpen, props.selectedIds]);

  const filteredusers =
    users?.filter((user) => {
      const searchMatched =
        !keyword || user.name?.toLowerCase().includes(keyword.toLowerCase());
      return searchMatched;
    }) || [];

  const handleSelect = (id: number) => {
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
    } else {
      setSelected([...(selected || []).filter((c) => c !== id)]);
    }
  };

  return (
    <Confirm isOpen={props.isOpen} onClose={props.onClose} size="small">
      <div className="w-full text-lg mb-5">
        <p className="text-center my-5">
          Choose one or more users to send this message to
        </p>
        <div className="max-w-2xl m-auto">
          {isLoading ? (
            <Spinner />
          ) : (
            !!users?.length && (
              <>
                <div className="mb-5 pr-8">
                  <input
                    className="outline-none border border-hubBlack p-4 rounded w-full"
                    type="text"
                    value={keyword}
                    name="search"
                    placeholder="Type the name of the user here"
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
                <div className={styles.list}>
                  {filteredusers.length ? (
                    filteredusers.map((user, key) => {
                      const isSelected = selected.includes(user.id);
                      return (
                        <p
                          key={key}
                          className={`flex justify-between items-center cursor-pointer ${
                            isSelected ? styles.selected : ""
                          }`}
                          onClick={() => handleSelect(user.id)}
                        >
                          {user.name}
                          <label className={`${styles.checkbox}`}>
                            <span className={`${styles.checkmark}`}></span>
                          </label>
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-center mt-2 text-gray-400">
                      No available users.
                    </p>
                  )}
                </div>
              </>
            )
          )}
        </div>
        {!isLoading && (
          <>
            <div className="flex justify-center">
              <button
                className="btnGreenLt px-10 py-4 text-base font-medium"
                onClick={() => {
                  props.onSave(selected);
                  props.onClose?.();
                }}
              >
                Select users
              </button>
            </div>
            <p
              className="cursor-pointer underline text-hubRed text-center mt-5 text-reg"
              onClick={() => setSelected([])}
            >
              Clear selection
            </p>
          </>
        )}
      </div>
    </Confirm>
  );
}
