import { axiosQip as axios } from "../services/axios";

export async function revenue(): Promise<{
  sum_of_monthly: number;
  sum_of_yearly: number;
}> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get("/api/stripe/revenue", config);

  return response?.data[0];
}

export const stripeApi = {
  revenue,
};
