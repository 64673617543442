import React, { useState } from "react";
import BaseModal from "../../BaseModal";
import styles from "./UpdateSubscription.module.css";
import { Dropdown } from "semantic-ui-react";
import Confirm from "../Confirm";
import Spinner from "../../Spinner";
import moment from "moment";
import { pricing } from "../../../pages/Subscriptionsv2/constants/pricing";
import { isEmpty } from "lodash";

type IUpdate = {
  product?: string;
  interval: string;
  userId: string;
  centerId: string;
  priceId?: string | null;
  endDate: string;
  subscriptionId?: string | null;
  quantity?: number;
};

interface IUpdateSubscription {
  isManual: boolean;
  isOpen: boolean;
  isLoadingUpdate: boolean;
  onClose?: () => void;
  onUpdateConfirm?: () => void;
  onCancelSubNow?: () => void;
  onCancelTrial?: () => void;
  data: IUpdate;
  setDataToUpdate: (data: IUpdate) => void;
  onCancelEndOfBillingCycle?: () => void;
}

const subOptions = [
  { key: "trial", text: "Trial", value: "trial" },
  {
    key: "Holistic QIP",
    text: "Qip",
    value: "Holistic QIP",
  },
  {
    key: "Holistic QIP + Mentoring",
    text: "QIP+Mentoring",
    value: "Holistic QIP + Mentoring",
  },
  {
    key: "Holistic Management Portal",
    text: "HMC Portal",
    value: "Holistic Management Portal",
  },
  {
    key: "Holistic Management Portal + Mentoring",
    text: "HMC Portal +Mentoring",
    value: "Holistic Management Portal + Mentoring",
  },
  {
    key: "Holistic QIP & Holistic Management Portal",
    text: "QIP & HMC Portal",
    value: "Holistic QIP & Holistic Management Portal",
  },
  {
    key: "Holistic QIP & Management Portal + Mentoring",
    text: "QIP & HMC Portal +Mentoring",
    value: "Holistic QIP & Management Portal + Mentoring",
  },
];

const UpdateSubscription: React.FC<IUpdateSubscription> = (
  props
): JSX.Element => {
  const {
    isManual,
    isOpen,
    isLoadingUpdate,
    onClose,
    onUpdateConfirm,
    onCancelSubNow,
    onCancelTrial,
    data,
    setDataToUpdate,
    onCancelEndOfBillingCycle,
  } = props;

  const [openConfirmUpdate, setOpenConfirmUpdate] = useState<boolean>(false);

  const [openConfirmCancelTrial, setOpenConfirmCancelTrial] =
    useState<boolean>(false);

  const [openConfirmCancelNotTrial, setOpenConfirmCancelNotTrial] =
    useState<boolean>(false);

  const isTrial = data.product === "trial";
  //   const isTrial = false;

  const [updatedEndDate, setUpdatedEndDate] = useState(
    moment(data.endDate, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD")
  );

  return (
    <BaseModal isOpen={isOpen} onClose={onClose} size="small">
      {openConfirmUpdate && (
        <Confirm
          isOpen={openConfirmUpdate}
          size="tiny"
          text="Are you sure you want to update subscription?"
          onConfirm={
            isLoadingUpdate
              ? undefined
              : () => {
                  //   setDataToUpdate({
                  //     ...data,
                  //     endDate: moment(updatedEndDate, "YYYY-MM-DD").format(
                  //       "YYYY-MM-DD hh:mm:ss"
                  //     ),
                  //   });
                  onUpdateConfirm && onUpdateConfirm();
                }
          }
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmUpdate(false)
          }
          onClose={() => setOpenConfirmUpdate(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Updating subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelTrial && (
        <Confirm
          isOpen={openConfirmCancelTrial}
          size="tiny"
          text="Are you sure you want to cancel subscription?"
          onConfirm={isLoadingUpdate ? undefined : onCancelTrial}
          onDecline={
            isLoadingUpdate ? undefined : () => setOpenConfirmCancelTrial(false)
          }
          onClose={() => setOpenConfirmCancelTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Canceling subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {openConfirmCancelNotTrial && (
        <Confirm
          isOpen={openConfirmCancelNotTrial}
          size="tiny"
          text="When should subscription be cancelled?"
          confirmText="Cancel Now"
          declineText="End of billing cycle"
          onConfirm={isLoadingUpdate ? undefined : onCancelSubNow}
          onDecline={isLoadingUpdate ? undefined : onCancelEndOfBillingCycle}
          onClose={() => setOpenConfirmCancelNotTrial(false)}
        >
          {isLoadingUpdate && (
            <div>
              <Spinner text="Updating subscription..." />
            </div>
          )}
        </Confirm>
      )}
      <div className={`mx-20 mb-20`}>
        <div className={`${styles.title} mb-2`}>Update subscription</div>
        <div
          className={`${styles.desc}`}
        >{`Update, cancel and delete trials & subscriptions`}</div>

        <div className={`w-full flex justify-between items-center my-14`}>
          <div>
            <h4 className={`${styles.label} pb-2`}>Current subscription</h4>
            <Dropdown
              className={`${styles.dropdown} `}
              disabled={isTrial || !isManual}
              selection
              defaultValue={data.product}
              options={[
                ...subOptions.filter((sub) => sub.value === data.product),
                ...subOptions.filter((sub) => sub.value !== data.product),
              ]}
              onChange={(e, val) => {
                const selectedPriceId = pricing().filter(
                  (p) => p.interval === data.interval && p.product === val.value
                );

                if (!isEmpty(selectedPriceId)) {
                  setDataToUpdate({
                    ...data,
                    priceId: selectedPriceId[0].price_id,
                  });
                }
              }}
            />
          </div>

          <div>
            <h4 className={`${styles.label} pb-2`}>Update end date</h4>
            <input
              disabled={!isManual}
              value={updatedEndDate}
              className={`${styles.dateInput} px-3`}
              onChange={(e) => {
                setUpdatedEndDate(e.target.value);

                setDataToUpdate({
                  ...data,
                  endDate: moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DD hh:mm:ss"
                  ),
                });
              }}
              type={`date`}
            />
          </div>
        </div>
        {isManual && (
          <div className={`w-full flex justify-center items-center`}>
            <button
              onClick={() => {
                setOpenConfirmUpdate(true);
              }}
              className={`${styles.blueBtn} mr-5`}
            >
              Update
            </button>
            <button
              onClick={() => {
                isTrial
                  ? setOpenConfirmCancelTrial(true)
                  : setOpenConfirmCancelNotTrial(true);
              }}
              className={`${styles.redBtn} ml-5`}
            >
              Cancel
            </button>
          </div>
        )}
        {!isManual && (
          <div className={`w-full flex flex-col justify-center items-center`}>
            <div
              className={`${styles.isNotManualDesc}`}
            >{`This subscription is paid via Stripe. Updates should be made directly by the subscriber, or in the Stripe. You can cancel the subscription below, which would take affect at the end of the user’s current billing cycle.`}</div>
            <button onClick={onClose} className={`${styles.redBtn} mt-10`}>
              Cancel
            </button>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default UpdateSubscription;
