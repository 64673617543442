import { useEffect, useMemo, useState } from "react";
import {
  FaArrowLeft,
  FaEnvelope,
  FaSpinner,
  FaTimesCircle,
  FaUserFriends,
  FaUsers,
  FaUserShield,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import styles from "./Messages.module.css";
import ChooseServices from "../../components/Popups/ChooseServices";
import { Center } from "../../api/service";
import useFetchServices from "../../hooks/useFetchServices";
import SendingMessage from "../../components/Popups/SendingMessage";
import { useMutation, useQueryClient } from "react-query";
import { messagesApi, MsgUser } from "../../api/messages";
import { alertMessage } from "../../components/Popups/Alert";
import { DraftMessage } from ".";
import useFetchMsgUsers from "../../hooks/useFetchMsgUsers";
import ChooseUsers from "../../components/Popups/ChooseUsers";

const QUILL_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "string"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image"],
  ],
};

export enum RECEIVER_TYPE {
  all = 1,
  admins = 2,
  centers = 3,
  user = 4,
}

type ComposeProps = {
  draftMessage?: DraftMessage;
};

const Compose = (props: ComposeProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { draftMessage } = props;

  const [content, setContent] = useState(draftMessage?.content || "");
  const [subject, setSubject] = useState(draftMessage?.subject || "");
  const [isDraft, setIsDraft] = useState<number>();
  const [selectedServices, setSelectedServices] = useState<Center[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<MsgUser[]>([]);
  const [selectedReceiver, setSelectedReceiver] = useState(
    draftMessage?.user_to?.toLowerCase() === "admins only"
      ? RECEIVER_TYPE.admins
      : !!draftMessage?.send_to?.length
      ? RECEIVER_TYPE.centers
      : !!draftMessage?.sent_to?.length
      ? RECEIVER_TYPE.user
      : RECEIVER_TYPE.all
  );
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const { data: services } = useFetchServices(isOpenServices);
  const { data: users } = useFetchMsgUsers(isOpenUsers);
  const [isOpenTimer, setIsOpenTimer] = useState(false);
  const [validate, setValidate] = useState(false);

  const onComposeSuccess = () => {
    queryClient.invalidateQueries("messages");
    alertMessage("success", "Message Sent!").then(() => {
      history.push("/messages");
    });
  };

  const onDraftSuccess = () => {
    queryClient.invalidateQueries("messages");
    alertMessage("success", "Message save as draft").then(() => {
      history.push("/messages");
    });
  };

  const { mutate: sendMessageAdmins, isLoading: sendingMessageAdmins } =
    useMutation(messagesApi.messageAdmins, {
      onMutate: ({ is_draft }) => {
        setIsDraft(is_draft);
      },
      onSuccess: (e, { is_draft }) => {
        setIsDraft(undefined);
        is_draft === 0 ? onComposeSuccess() : onDraftSuccess();
      },
    });

  const { mutate: sendMessageAll, isLoading: sendingMessageAll } = useMutation(
    messagesApi.messageAll,
    {
      onMutate: ({ is_draft }) => {
        setIsDraft(is_draft);
      },
      onSuccess: (e, { is_draft }) => {
        setIsDraft(undefined);
        is_draft === 0 ? onComposeSuccess() : onDraftSuccess();
      },
    }
  );

  const { mutate: sendMessageServices, isLoading: sendingMessageServices } =
    useMutation(messagesApi.messageServices, {
      onMutate: ({ is_draft }) => {
        setIsDraft(is_draft);
      },
      onSuccess: (e, { is_draft }) => {
        setIsDraft(undefined);
        is_draft === 0 ? onComposeSuccess() : onDraftSuccess();
      },
    });

  const { mutate: sendMessageUsers, isLoading: sendingMessageUsers } =
    useMutation(messagesApi.messageUsers, {
      onMutate: ({ is_draft }) => {
        setIsDraft(is_draft);
      },
      onSuccess: (e, { is_draft }) => {
        setIsDraft(undefined);
        is_draft === 0 ? onComposeSuccess() : onDraftSuccess();
      },
    });

  const { mutate: updateMessage, isLoading: updatingMessage } = useMutation(
    messagesApi.updateMessage,
    {
      onMutate: ({ message }) => {
        setIsDraft(message.is_draft);
      },
      onSuccess: (e, { message }) => {
        setIsDraft(undefined);
        message.is_draft === 0 ? onComposeSuccess() : onDraftSuccess();
      },
    }
  );

  const onSelectServices = (ids: number[]) => {
    const selected = (services || []).filter((s) => ids.includes(s.id));
    setSelectedServices(selected);
  };

  const onSelectUsers = (ids: number[]) => {
    const selected = (users || []).filter((s) => ids.includes(s.id));
    setSelectedUsers(selected);
  };

  const onRemoveService = (id: number) => {
    const updated = (selectedServices || []).filter((s) => s.id !== id);
    setSelectedServices(updated);
  };

  const onRemoveUser = (id: number) => {
    const updated = (selectedUsers || []).filter((s) => s.id !== id);
    setSelectedUsers(updated);
  };

  const onSend = (is_draft = 0) => {
    if (draftMessage?.id) {
      let ids: number[] = [];
      switch (selectedReceiver) {
        case RECEIVER_TYPE.all:
          ids = [];
          break;
        case RECEIVER_TYPE.admins:
          ids = [];
          break;
        case RECEIVER_TYPE.centers:
          ids = selectedServices.map((s) => s.id);
          break;
        case RECEIVER_TYPE.user:
          ids = selectedUsers.map((s) => s.id);
          break;
      }

      updateMessage({
        id: draftMessage.id,
        message: {
          is_draft,
          content,
          subject,
          ids,
          options: selectedReceiver,
        },
      });

      return;
    }

    switch (selectedReceiver) {
      case RECEIVER_TYPE.all:
        sendMessageAll({ is_draft, content, subject });
        break;
      case RECEIVER_TYPE.admins:
        sendMessageAdmins({ is_draft, content, subject });
        break;
      case RECEIVER_TYPE.centers:
        sendMessageServices({
          is_draft,
          content,
          subject,
          center_id: selectedServices.map((s) => s.id),
        });
        break;
      case RECEIVER_TYPE.user:
        sendMessageUsers({
          is_draft,
          content,
          subject,
          user_to: selectedUsers.map((s) => s.id),
        });
        break;
    }
  };

  const isValidReceiver =
    selectedReceiver === RECEIVER_TYPE.all ||
    selectedReceiver === RECEIVER_TYPE.admins ||
    (selectedReceiver === RECEIVER_TYPE.centers && !!selectedServices.length) ||
    (selectedReceiver === RECEIVER_TYPE.user && !!selectedUsers.length);
  const onValidate = (onValid: () => void) => {
    setValidate(true);
    const isValidFields = !!subject.length && !!content.length;

    if (isValidFields && isValidReceiver) {
      setValidate(false);
      onValid();
    }
  };

  const isSending = useMemo(
    () =>
      sendingMessageAll ||
      sendingMessageAdmins ||
      sendingMessageServices ||
      sendingMessageUsers ||
      updatingMessage,
    [
      sendingMessageAll,
      sendingMessageAdmins,
      sendingMessageServices,
      sendingMessageUsers,
      updatingMessage,
    ]
  );

  useEffect(() => {
    if (!draftMessage) return;

    if (draftMessage.option === RECEIVER_TYPE.centers) {
      const selected = (draftMessage.center_id || []).map((c, i) => ({
        id: c,
        name: (draftMessage.send_to || [])[i] || "",
      }));

      setSelectedServices(selected);
    } else if (draftMessage.option === RECEIVER_TYPE.user) {
      const selected = (draftMessage.user_id || []).map((c, i) => ({
        id: c,
        name: (draftMessage.sent_to || [])[i] || "",
      }));

      setSelectedUsers(selected);
    }
  }, [services, draftMessage]);

  return (
    <>
      {isOpenServices && (
        <ChooseServices
          isOpen={isOpenServices}
          onClose={() => setIsOpenServices(false)}
          selectedIds={selectedServices?.map((s) => s.id)}
          onSave={onSelectServices}
        />
      )}
      {isOpenUsers && (
        <ChooseUsers
          isOpen={isOpenUsers}
          onClose={() => setIsOpenUsers(false)}
          selectedIds={selectedUsers?.map((s) => s.id)}
          onSave={onSelectUsers}
        />
      )}
      {isOpenTimer && (
        <SendingMessage
          isOpen={isOpenTimer}
          onClose={() => setIsOpenTimer(false)}
          onConfirm={() => {
            setIsOpenTimer(false);
            onSend();
          }}
        />
      )}
      <div
        className={`${styles.mainContainer} flex-1 px-2 mt-10 md:mb-28 lg:mb-10`}
      >
        <div className="flex justify-between">
          <div className="flex">
            <div className={styles.headerTextIcon}>
              <FaEnvelope size={55} />
            </div>
            <div className={`${styles.headerText} ml-4`}>
              <h1>Messages</h1>
              <p>View and delete messages sent to users across products</p>
            </div>
          </div>
          <div className="flex items-center">
            <button
              className="flex items-center p-5 text-lg font-medium rounded
             bg-white bg-opacity-80 text-hubBlack text-opacity-20
              hover:bg-opacity-100 hover:text-opacity-50"
              onClick={() => history.push("/messages")}
            >
              <FaArrowLeft className="mr-2" />
              Back to messages
            </button>
          </div>
        </div>
        <div
          className={`${styles.container} md:px-8 md:py-12 px-12 py-16 mt-10`}
        >
          <p className="text-2xl">Compose message</p>
          <div className="border-t border-b py-10 my-10">
            <div className="grid grid-cols-4 gap-4">
              <div className={styles.iconReceiver}>
                <div
                  className={
                    selectedReceiver === RECEIVER_TYPE.all
                      ? styles.iconActive
                      : styles.icon
                  }
                  onClick={() => setSelectedReceiver(RECEIVER_TYPE.all)}
                >
                  <FaUsers size={30} />
                </div>
                <div className={styles.iconLabel}>
                  <p>Everybody</p>
                  <p>To all users & admins</p>
                </div>
              </div>
              <div className={styles.iconReceiver}>
                <div
                  className={
                    selectedReceiver === RECEIVER_TYPE.admins
                      ? styles.iconActive
                      : styles.icon
                  }
                  onClick={() => setSelectedReceiver(RECEIVER_TYPE.admins)}
                >
                  <FaUserShield size={30} />
                </div>
                <div className={styles.iconLabel}>
                  <p>Admins only</p>
                  <p>Only for other admins</p>
                </div>
              </div>
              <div className={styles.iconReceiver}>
                <div
                  className={
                    selectedReceiver === RECEIVER_TYPE.centers
                      ? styles.iconActive
                      : styles.icon
                  }
                  onClick={() => {
                    setSelectedReceiver(RECEIVER_TYPE.centers);
                    setIsOpenServices(true);
                  }}
                >
                  <FaUserFriends size={30} />
                </div>
                <div className={styles.iconLabel}>
                  <p>Service teammates</p>
                  <p>Seen by a service’s users</p>
                </div>
              </div>
              <div className={styles.iconReceiver}>
                <div
                  className={
                    selectedReceiver === RECEIVER_TYPE.user
                      ? styles.iconActive
                      : styles.icon
                  }
                  onClick={() => {
                    setSelectedReceiver(RECEIVER_TYPE.user);
                    setIsOpenUsers(true);
                  }}
                >
                  <FaUserFriends size={30} />
                </div>
                <div className={styles.iconLabel}>
                  <p>Selected user/s</p>
                  <p>Seen only by one user</p>
                </div>
              </div>
            </div>
          </div>
          {validate && !isValidReceiver && (
            <div className="text-red-400 -mt-5 font-thin italic text-sm ">
              Please select recipient.
            </div>
          )}
          <div className="mt-10 text-lg flex flex-col gap-y-5">
            {selectedReceiver === RECEIVER_TYPE.centers &&
              !!selectedServices.length && (
                <div>
                  <p>Services that will receive this message</p>
                  <div className="flex flex-wrap gap-3 mt-2">
                    {selectedServices.map((s, i) => (
                      <div
                        key={i}
                        className="text-hubGreen bg-hubGreen bg-opacity-20 text-lg px-5 py-3 rounded flex justify-between items-center"
                      >
                        {s.name}
                        <span
                          className="ml-3 cursor-pointer"
                          onClick={() => onRemoveService(s.id)}
                        >
                          <FaTimesCircle />
                        </span>
                      </div>
                    ))}
                    <button
                      className="btnRed px-7"
                      onClick={() => setSelectedServices([])}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}

            {selectedReceiver === RECEIVER_TYPE.user && !!selectedUsers.length && (
              <div>
                <p>Users who will receive this message</p>
                <div className="flex flex-wrap gap-3 mt-2">
                  {selectedUsers.map((s, i) => (
                    <div
                      key={i}
                      className="text-hubGreen bg-hubGreen bg-opacity-20 text-lg px-5 py-3 rounded flex justify-between items-center"
                    >
                      {s.name}
                      <span
                        className="ml-3 cursor-pointer"
                        onClick={() => onRemoveUser(s.id)}
                      >
                        <FaTimesCircle />
                      </span>
                    </div>
                  ))}
                  <button
                    className="btnRed px-7"
                    onClick={() => setSelectedUsers([])}
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-y-2">
              <label>
                Message subject<span className="text-red-400">*</span>
              </label>
              <input
                className="outline-none border border-hubBlack p-4 rounded"
                placeholder="Subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {validate && !subject.length && (
                <div className="text-red-400 p-2 font-thin italic text-sm">
                  This field is required.
                </div>
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <label>
                Message<span className="text-red-400">*</span>
              </label>

              <div className={`h-96 pb-14 `}>
                <ReactQuill
                  className={`h-full ${styles.editor}`}
                  modules={QUILL_MODULES}
                  value={content}
                  onChange={(value) => {
                    if (value.trim() === "<p><br></p>") {
                      setContent("");
                    } else {
                      setContent(value);
                    }
                  }}
                />
              </div>

              {validate && !content.length && (
                <div className="text-red-400 p-2 font-thin italic text-sm">
                  This field is required.
                </div>
              )}
            </div>
            <div className="flex justify-center gap-x-5">
              <button
                className="btnGreenLt px-10 py-4"
                disabled={isDraft === 1 && isSending}
                onClick={() => onValidate(() => onSend(1))}
              >
                {isDraft === 1 && isSending ? (
                  <div className="flex justify-center items-center">
                    <FaSpinner className="text-6xl animate-spin mr-2" />
                    <span>Saving draft ...</span>
                  </div>
                ) : (
                  "Save as draft"
                )}
              </button>
              <button
                className="btnBlueLt px-8 py-4"
                onClick={() => onValidate(() => setIsOpenTimer(true))}
                disabled={isDraft === 0 && isSending}
              >
                {isDraft === 0 && isSending ? (
                  <div className="flex justify-center items-center">
                    <FaSpinner className="text-6xl animate-spin mr-2" />
                    <span>Sending ...</span>
                  </div>
                ) : (
                  "Send message"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compose;
