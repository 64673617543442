import React, { lazy } from "react";
import PrivateRoute from "../../components/PrivateRoute";
import { Routes } from "../../context/App";

const Users = lazy(() => import("../../pages/Users"));

interface UsersProps {
  route: typeof Routes.USERS;
  path: string;
  exact: boolean;
}

const UsersRoute: React.FC<UsersProps> = (props) => {
  const { route, path, exact } = props;
  return (
    <PrivateRoute path={path} route={route} exact={exact} Component={Users} />
  );
};

export default UsersRoute;
