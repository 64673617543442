import { ICenterUser } from "../types";
import { RoutingScheme } from "../facades/RoutingScheme";
import generateRandom from "../utils/generateRandom";
import { generatePlainText } from "../services/crypto";
import { getUserLoggedIn } from "../services/auth";

export const redirect = (serviceUser: ICenterUser) => {
  const hostname = new URL(window.location.href).hostname.split(".");

  let token = localStorage.getItem("hubAdminToken") || "";

  let viewer = serviceUser;

  let appURL = process.env.REACT_APP_ADMIN_PORTAL_URL;

  const viewerCipher = RoutingScheme.payload(JSON.stringify(viewer))
    .generateIV()
    .generateSalt()
    .generateCipher();

  const cipherToken = RoutingScheme.payload(token)
    .generateIV()
    .generateSalt()
    .generateCipher();

  const cipherAuth = RoutingScheme.payload(JSON.stringify(serviceUser))
    .generateIV()
    .generateSalt()
    .generateCipher();

  const viewerHash = RoutingScheme.generateSignature(JSON.stringify(viewer));

  const TokenHash = RoutingScheme.generateSignature(token);

  const AuthHash = RoutingScheme.generateSignature(JSON.stringify(serviceUser));

  const counter = generateRandom(12);

  if (hostname[0] === "localhost") {
    window.open(
      "http://localhost:3001/view-hub-admin-service?accessToken=" +
        encodeURIComponent(cipherToken) +
        "&tokenHash=" +
        encodeURIComponent(TokenHash) +
        "&auth=" +
        encodeURIComponent(cipherAuth) +
        "&authHash=" +
        encodeURIComponent(AuthHash) +
        "&viewer=" +
        encodeURIComponent(viewerCipher) +
        "&viewerHash=" +
        encodeURIComponent(viewerHash) +
        "&counter=" +
        counter
    );
  } else {
    window.open(
      appURL +
        "view-hub-admin-service?accessToken=" +
        encodeURIComponent(cipherToken) +
        "&tokenHash=" +
        encodeURIComponent(TokenHash) +
        "&auth=" +
        encodeURIComponent(cipherAuth) +
        "&authHash=" +
        encodeURIComponent(AuthHash) +
        "&viewer=" +
        encodeURIComponent(viewerCipher) +
        "&viewerHash=" +
        encodeURIComponent(viewerHash) +
        "&counter=" +
        counter
    );
  }
};

export default redirect;
