import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import { IAuthUser, ILoginCredentials } from "../../types";

export interface AuthModel {
  setAuthUser: Action<AuthModel, IAuthUser>;
}

const authStore: AuthModel = {
  setAuthUser: action((state, payload) => {
    localStorage.setItem("authUser", JSON.stringify(payload));
  }),
};

export default authStore;
