import React from "react";
import {
  BrowserRouter,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "../../components/Layout";
import { AppContext, Routes } from "../../context/App";
import Subscriptionsv2 from "../../pages/Subscriptionsv2";
import { SUBPAGES as USERS_SUBPAGES } from "../../pages/Users";
import { SUBPAGES as MESSAGES_SUBPAGES } from "../../pages/Messages";
import AppUsageRoute from "../AppUsage";
import MessagesRoute from "../Messages";
import UsersRoute from "../Users";

const LoggedInPages = () => {
  const match = useRouteMatch();
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <AppContext.Provider value={{ route: Routes.HOMEPAGE, authed: true }}>
        <Switch>
          <AppUsageRoute
            path={`/app-usage`}
            exact={true}
            route={Routes.APPUSAGE}
          />

          <UsersRoute path={`/users`} exact={true} route={Routes.USERS} />

          <UsersRoute
            path={`/users/:page(${Object.values(USERS_SUBPAGES).join("|")})`}
            exact={false}
            route={Routes.USERS}
          />

          <MessagesRoute
            path={`/messages`}
            exact={true}
            route={Routes.MESSAGES}
          />

          <MessagesRoute
            path={`/messages/:page(${Object.values(MESSAGES_SUBPAGES).join(
              "|"
            )})`}
            exact={false}
            route={Routes.MESSAGES}
          />

          <Route
            exact
            path={`/`}
            render={(props) => {
              return (
                <Layout
                  route={Routes.SUBSCRIPTIONS}
                  Children={Subscriptionsv2}
                  {...props}
                />
              );
            }}
          />

          <Route
            path={`${match.path}`}
            render={() => {
              return <Redirect to={`/`} />;
            }}
          />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default LoggedInPages;
