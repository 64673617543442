import moment from "moment";
import { useState } from "react";
import {
  FaCaretDown,
  FaCaretUp,
  FaCog,
  FaEnvelope,
  FaSearch,
} from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { Message, messagesApi } from "../../api/messages";
import Pagination from "../../components/Pagination";
import Confirm from "../../components/Popups/Confirm";
import Spinner from "../../components/Spinner";
import { STALE_TIME } from "../../services/query";
import { RECEIVER_TYPE } from "./Compose";
import styles from "./Messages.module.css";

const maxSentToLength = 20;
const SentToList = (props: { arr: string[]; onShowMore: () => void }) => {
  const allSentTo = props.arr.join(", ");
  const trimmedString = allSentTo.substring(0, maxSentToLength);
  const shownLength = trimmedString.split(", ").length;
  const hasMore = allSentTo.length > maxSentToLength;
  const moreCount = props.arr.length - shownLength;

  return (
    <div className="flex justify-between items-center flex-wrap gap-y-2">
      {trimmedString}
      {hasMore ? " ..." : ""}
      {!!moreCount && (
        <button className="btnFilter" onClick={props.onShowMore}>
          +{moreCount} more
        </button>
      )}
    </div>
  );
};

const SortIcon = ({ up }: { up: boolean }) => {
  return up ? (
    <FaCaretUp className="inline-flex ml-1" size={12} />
  ) : (
    <FaCaretDown className="inline-flex ml-1  mb-0.5" size={12} />
  );
};

const CogOptions = (props: { message: Message; onDelete: () => void }) => {
  const history = useHistory();
  const { message, onDelete } = props;

  return (
    <ul className="bg-hubGreen text-white text-reg rounded-tl">
      <li
        className="pl-5 py-3 pr-20 cursor-pointer"
        style={{
          borderBottom: "1px solid #7AAF26",
        }}
        onClick={() => {
          if (message.is_draft === 0) {
            history.push(`/messages/view`, { message });
          } else if (message.is_draft === 1) {
            history.push(`/messages/draft`, { message });
          }
        }}
      >
        View
      </li>

      <li className="pl-5 py-3 pr-20 cursor-pointer" onClick={onDelete}>
        Delete
      </li>
    </ul>
  );
};

const MSG_PER_PAGE = 10;
const ViewAll = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [columnFilter, setColumnFilter] = useState("none");
  const [filterDir, setFilterDir] = useState(false);
  const [activeMsg, setActiveMsg] = useState<Message>();
  const [toDelete, setToDelete] = useState<Message>();
  const [showSuccessDeleted, setShowSuccessDeleted] = useState(false);
  const [toShowMore, setToShowMore] =
    useState<{ option?: number; list?: string[] }>();

  const { data, isFetching } = useQuery("messages", () => messagesApi.get(), {
    staleTime: STALE_TIME.HIGH,
  });

  const { mutate: deleteMsg, isLoading: isDeleting } = useMutation(
    messagesApi.deleteMsg,
    {
      onSuccess: () => {
        setToDelete(undefined);
        setShowSuccessDeleted(true);
        queryClient.invalidateQueries("messages");
      },
    }
  );

  const onConfirmDelete = () => {
    if (!toDelete) return;
    deleteMsg(toDelete.id);
  };

  const filteredMessages = (
    array: Message[],
    searchFilter: string,
    filterColumn: string,
    filterDir: boolean
  ): Message[] => {
    return array
      .filter((message) => {
        if (searchFilter.trim() === "") {
          return true;
        } else {
          return message.subject
            .toLowerCase()
            .includes(searchFilter.toLowerCase());
        }
      })
      .map((message) => {
        if (filterColumn === "sentto") {
          if (message.sent_to?.length) {
            return {
              ...message,
              sent_to: message.sent_to.sort(),
            };
          }
          if (message.send_to?.length) {
            return {
              ...message,
              send_to: message.send_to.sort(),
            };
          }

          if (message.user_to?.length) {
            return message;
          }

          return message;
        } else {
          return message;
        }
      })
      .sort((a, b): any => {
        switch (filterColumn) {
          case "sender":
            if (filterDir) {
              if (a.sender.toLowerCase() < b.sender.toLowerCase()) {
                return -1;
              }
              if (a.sender.toLowerCase() > b.sender.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.sender.toLowerCase() < b.sender.toLowerCase()) {
                return 1;
              }
              if (a.sender.toLowerCase() > b.sender.toLowerCase()) {
                return -1;
              }
              return 0;
            }
          case "subject":
            if (filterDir) {
              if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
                return -1;
              }
              if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
                return 1;
              }
              if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
                return -1;
              }
              return 0;
            }
          case "status":
            if (filterDir) {
              return a.is_draft - b.is_draft;
            } else {
              return b.is_draft - a.is_draft;
            }
          case "date":
            if (filterDir) {
              return moment(a.date, "DD-MM-YYYY").diff(
                moment(b.date, "DD-MM-YYYY")
              );
            } else {
              return moment(b.date, "DD-MM-YYYY").diff(
                moment(a.date, "DD-MM-YYYY")
              );
            }
        }
      });
  };

  const onSort = (column: string) => {
    setColumnFilter(column);
    (column === columnFilter || !filterDir) && setFilterDir(!filterDir);
  };

  const msgs = filteredMessages(data || [], searchKey, columnFilter, filterDir);
  return (
    <>
      {toDelete && (
        <Confirm
          isOpen={!!toDelete}
          size="tiny"
          text="Are you sure you want to delete this message?"
          onConfirm={isDeleting ? undefined : onConfirmDelete}
          onDecline={isDeleting ? undefined : () => setToDelete(undefined)}
          onClose={() => setToDelete(undefined)}
        >
          {isDeleting && (
            <div>
              <Spinner text="Deleting message..." />
            </div>
          )}
        </Confirm>
      )}
      {showSuccessDeleted && (
        <Confirm
          isOpen={showSuccessDeleted}
          size="small"
          onClose={() => setShowSuccessDeleted(false)}
        >
          <p className="text-xl mb-5">Message is now deleted</p>
          <button
            className="btnBlueLt px-16 py-4 text-lg"
            onClick={() => setShowSuccessDeleted(false)}
          >
            Ok
          </button>
        </Confirm>
      )}
      {toShowMore && (
        <Confirm
          isOpen={!!toShowMore}
          size="small"
          confirmText="OK"
          onConfirm={() => setToShowMore(undefined)}
          onClose={() => setToShowMore(undefined)}
        >
          <div className="text-lg w-4/5">
            {toShowMore.option === RECEIVER_TYPE.user ? (
              <p className="text-center">Users who received this message</p>
            ) : toShowMore.option === RECEIVER_TYPE.centers ? (
              <p className="text-center">Services that received this message</p>
            ) : null}

            <div className={styles.listShowMore}>
              {toShowMore.list?.map((user, key) => (
                <p key={key}>{user}</p>
              ))}
            </div>
          </div>
        </Confirm>
      )}
      <div
        className={`${styles.mainContainer} flex-1 px-2 mt-10 md:mb-28 lg:mb-10`}
      >
        <div className="flex justify-between">
          <div className="flex">
            <div className={styles.headerTextIcon}>
              <FaEnvelope size={55} />
            </div>
            <div className={`${styles.headerText} ml-4`}>
              <h1>Sent messages</h1>
              <p>View and delete messages sent to users across products</p>
            </div>
          </div>
          <div className="flex items-center">
            <button
              className={styles.createMsg}
              onClick={() => history.push("/messages/compose")}
            >
              <FaEnvelope className="mr-2" />
              Create message
            </button>
          </div>
        </div>
        <div
          className={`${styles.container} md:px-8 md:py-12 px-12 py-16 mt-10`}
        >
          <div className="flex justify-between">
            <p className="text-2xl">Sent messages</p>
            <div className="flex border border-hubGrayLt p-1">
              <input
                className="outline-none p-2"
                type="text"
                placeholder="Search messages"
                onChange={(e) => {
                  if (currentPage !== 1) {
                    setCurrentPage(1);
                  }
                  setSearchKey(e.target.value);
                }}
              />
              <div className="flex">
                <div className="bg-hubGreen text-white flex justify-center items-center px-2.5">
                  <FaSearch />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 relative" style={{ minHeight: "25vh" }}>
            {isFetching && (
              <div className="absolute w-full h-full bg-hubGrayLt bg-opacity-60 pt-10">
                <Spinner />
              </div>
            )}
            <table className="table-fixed w-full text-reg">
              <thead>
                <tr className="cursor-pointer">
                  <th className="w-3/12" onClick={() => onSort("sender")}>
                    Sender
                    <SortIcon up={columnFilter === "sender" && filterDir} />
                  </th>
                  <th className="w-4/12" onClick={() => onSort("subject")}>
                    Message subject
                    <SortIcon up={columnFilter === "subject" && filterDir} />
                  </th>
                  <th className="w-28" onClick={() => onSort("status")}>
                    Status
                    <SortIcon up={columnFilter === "status" && filterDir} />
                  </th>
                  <th className="w-4/12" onClick={() => onSort("sentto")}>
                    Sent to
                    <SortIcon up={columnFilter === "sentto" && filterDir} />
                  </th>
                  <th className="w-36" onClick={() => onSort("date")}>
                    Sent date
                    <SortIcon up={columnFilter === "date" && filterDir} />
                  </th>
                  <th className="w-16"></th>
                </tr>
              </thead>
              <tbody>
                <>
                  {msgs
                    .slice(
                      currentPage * MSG_PER_PAGE - MSG_PER_PAGE,
                      currentPage * MSG_PER_PAGE
                    )
                    .map((m) => (
                      <tr key={m.id}>
                        <td>{m.sender}</td>
                        <td className="truncate text-hubBlueLt">
                          <Link
                            to={{
                              pathname:
                                m.is_draft === 1
                                  ? "/messages/draft"
                                  : "/messages/view",
                              state: { message: m },
                            }}
                            className="underline"
                          >
                            {m.subject}
                          </Link>
                        </td>
                        <td>
                          {m.is_draft === 1 ? (
                            <span className="text-hubRed"> Draft</span>
                          ) : (
                            <span className="text-hubGreen">Sent</span>
                          )}
                        </td>
                        <td>
                          {m.user_to ? (
                            m.user_to
                          ) : m.sent_to?.length ? (
                            <SentToList
                              arr={m.sent_to}
                              onShowMore={() =>
                                setToShowMore({
                                  option: m.option,
                                  list: m.sent_to,
                                })
                              }
                            />
                          ) : m.send_to?.length ? (
                            <SentToList
                              arr={m.send_to}
                              onShowMore={() =>
                                setToShowMore({
                                  option: m.option,
                                  list: m.send_to,
                                })
                              }
                            />
                          ) : null}
                        </td>
                        <td>{m.date.split("-").join("/")}</td>
                        <td className="p-0">
                          <Popup
                            pinned
                            basic
                            eventsEnabled
                            position="bottom right"
                            style={{
                              marginTop: "-3px",
                              marginRight: "-0.4px",
                              padding: 0,
                              border: 0,
                              boxShadow: "none",
                              borderTopRightRadius: 0,
                              zIndex: 999,
                            }}
                            content={
                              <CogOptions
                                message={m}
                                onDelete={() => setToDelete(m)}
                              />
                            }
                            hoverable
                            trigger={
                              <button
                                className={`btnFilter m-auto p-2 border-none my-1
                              ${
                                activeMsg?.id === m.id
                                  ? "bg-hubGreen text-white"
                                  : "bg-hubGrayLt2"
                              }`}
                              >
                                <FaCog />
                              </button>
                            }
                            onClose={() => setActiveMsg(undefined)}
                            onOpen={() => setActiveMsg(m)}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              </tbody>
            </table>
          </div>
          <div className="flex gap-2 mt-14 mb-5">
            {!isFetching && data && MSG_PER_PAGE < msgs.length && (
              <Pagination
                currentPage={currentPage}
                lastPage={Math.ceil(msgs.length / MSG_PER_PAGE)}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
