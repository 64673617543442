import { Redirect, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Message } from "../../api/messages";
import Compose from "./Compose";
import ViewAll from "./ViewAll";
import ViewMsg from "./ViewMsg";

export enum SUBPAGES {
  view = "view",
  compose = "compose",
  draft = "draft",
  all = "all",
}

export type DraftMessage = Message & {
  center_id?: number[];
};

const Messages = () => {
  const location = useLocation<{ message?: DraftMessage }>();
  const pageParams = useParams<{ page: string }>();
  const activePage = pageParams.page || SUBPAGES.all;

  const draftMessage = location.state?.message;
  return (
    (activePage === SUBPAGES.view && <ViewMsg />) ||
    (activePage === SUBPAGES.compose && <Compose />) ||
    (activePage === SUBPAGES.draft &&
      (draftMessage?.id ? (
        <Compose draftMessage={draftMessage} />
      ) : (
        <Redirect to="/messages" />
      ))) || <ViewAll />
  );
};
export default Messages;
