/***
 * RoutingScheme
 * __crypto.test.tsx__
 */

import { generateCipherText, generateHash } from "../services/crypto";
import * as Crypto from "crypto-js";

export class RoutingScheme {
  private static message: string = "";

  private static InitializationVector: Crypto.lib.WordArray =
    Crypto.lib.WordArray.random(16);

  private static Salt: Crypto.lib.WordArray = Crypto.lib.WordArray.random(16);

  /**
   * @param message
   */

  public static payload(message: string) {
    this.message = message;
    return this;
  }

  /***
   * @returns
   */
  public static generateIV() {
    const iv = Crypto.lib.WordArray.random(16);
    this.InitializationVector = iv;
    return this;
  }

  /**
   * @returns
   */
  public static generateSalt() {
    const salt = Crypto.lib.WordArray.random(16);
    this.Salt = salt;
    return this;
  }

  /**
   *
   * @returns
   */
  public static generateCipher() {
    return generateCipherText(
      this.message,
      this.InitializationVector,
      this.Salt
    );
  }

  /**
   *
   * @param message
   * @returns
   */
  public static generateSignature(message: string) {
    return generateHash(message);
  }
}
