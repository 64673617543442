import { debounce } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { FaCog, FaUsers, FaUserShield } from "react-icons/fa";
import { useQuery } from "react-query";
import { Popup, Search } from "semantic-ui-react";
import { serviceApi } from "../../api/service";
import { stripeApi } from "../../api/stripe";
import { usersApi } from "../../api/users";
import Filters from "./components/Filters";
import Summary from "./components/Summary";
import Table from "./components/Table";
import CogOptions from "./components/Table/CogOptions";
import { alertMessage } from "../../components/Popups/Alert";
import Confirm from "../../components/Popups/Confirm";
import UpdateSubscription from "../../components/Popups/UpdateSubscription";
import Spinner from "../../components/Spinner";
import { useMutation, useQueryClient } from "react-query";
import { subscriptionsApi } from "../../api/subscriptions";
import styles from "./Subscriptions.module.css";

const Subscriptionsv2 = () => {
  const queryClient = useQueryClient();
  const [activeView, setActiveView] = useState(true);
  const [serviceFilter, setServiceFilter] = useState("");
  const [subTypeFilter, setSubTypeFilter] = useState("");
  const [filterSelection, setFilterSelection] = useState<string>("email");
  const [columnFilter, setColumnFilter] = useState("none");
  const [filterDir, setFilterDir] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<any>();
  console.log('data', data);
  const [productList, setSubscriptionTypeList] = useState<any>();
  // const [productList, setSubscriptionTypeList] = useState<any>();
  const [orderBy, setOrderBy] = useState<
    "name" | "email" | "service" | "subscription" | "end date" | "status"
  >("name");
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [searchBy, setSearchBy] = useState<"name" | "email" | "service" | "subscription">(
    "email"
  );
 
  const [page, setPage] = useState<number>(1);

  const [toggleFetch, setToggleFetch] = useState<boolean>(false);

  const [currentServiceId, setCurrentServiceId] = useState<string>();
  const [toDelete, setToDelete] =
    useState<{ userId: string; centerId: string; isTrial: boolean }>();
  const [toRestore, setToRestore] =
    useState<{ userId: string; centerId: string; isTrial: boolean }>();
  const [toCancel, setToCancel] =
    useState<{ userId: string; centerId: string }>();

  const [toUpdate, setToUpdate] = useState<{
    product?: string;
    interval: string;
    userId: string;
    centerId: string;
    priceId?: string | null;
    endDate: string;
    subscriptionId?: string | null;
    quantity?: number;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const headers = [
    {
      name: "name",
      label: "Name",
      width: "w-2/12",
    },
    {
      name: "email",
      label: "Email",
      width: "w-2/12",
    },
    {
      name: "service",
      label: "Service",
      width: "w-1/5",
    },
    {
      name: "subscription",
      label: "Subscription",
      width: "w-1/7",
    },
    {
      name: "status",
      label: "Status",
      width: "w-1/10",
    },
    {
      name: "end date",
      label: "End Date",
      width: "w-1/10",
    },
    {
      name: "payment",
      label: "Payment",
      width: "w-1/10",
    },
    {
      name: "option",
      label: "",
      width: "",
    },
  ];

  const onOrder = (
    column:
      | "name"
      | "email"
      | "service"
      | "subscription"
      | "end date"
      | "status"
  ) => {
    // setServiceFilter("");

    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(column);
    setToggleFetch(!toggleFetch);
  };

  const Header = () => {
    return (
      <thead className="text-md">
        <tr>
          {headers.map((header: any) => {
            return (
              <th
                onClick={() => {
                  if (header.name !== "payment" && header.name !== "option") {
                    onOrder(header.name);
                  }
                }}
                className={`${header.width} cursor-pointer`}
              >
                {header.label}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const trialStatus = (center: any) => {};

  const Content = () => {
    return (
      <tbody className="text-sm">
        {data &&
          data.map((service: any) => {
            return (
              <tr>
                <td>{service.full_name}</td>
                <td>{service.email}</td>
                <td>
                  {service.subscription.map((sub: any) => {
                    return <p>{sub.center_name}</p>;
                  })}
                </td>
                <td>
                  {service.subscription.map((sub: any, key: number) => {
                    return (
                      <p key={key} className={`capitalize`}>
                        {sub.product_name}
                      </p>
                    );
                  })}
                </td>
                <td>
                  {" "}
                  {service.subscription.map((center: any, key: number) => (
                    <p key={key} className={`capitalize`}>
                      {center.product_name === "trial"
                        ? // center.subscription.trial_remaining_days.includes(
                          //     "-"
                          //   ) ||

                          moment(new Date()).diff(
                            moment(center.created_at, "DD-MM-YYYY"),
                            "days"
                          ) > 30
                          ? "Trial (Ended) "
                          : "Trial"
                        : center.cancel_at_period_end
                        ? "Canceled"
                        : center.status === "trialing"
                        ? "Trial"
                        : center.status === "incomplete_expired" ||
                          center.status === "incomplete" ||
                          center.status === "past_due"
                        ? "Payment Failed"
                        : center.status}
                    </p>
                  ))}
                </td>
                <td>
                  {service.subscription.map((center: any, key: number) => (
                    <p key={key} className={`capitalize`}>
                      {center.product_name === "trial"
                        ? moment(center.created_at, "DD-MM-YYYY")
                            .add(30, "days")
                            .format("DD/MM/YYYY")
                        : moment(
                            center.current_period_end,
                            "DD-MM-YYYY"
                          ).format("DD/MM/YYYY")}
                    </p>
                  ))}
                </td>

                <td>
                  {" "}
                  {service.subscription.map((center: any, key: number) => (
                    <p key={key}>
                      {center.product_name !== "trial"
                        ? `$${center.payment / 100} / ${center.interval}`
                        : `n/a`}
                    </p>
                  ))}
                </td>

                <td>
                  {service.subscription.map((center: any, key: number) => (
                    <Popup
                      key={key}
                      pinned
                      basic
                      inverted
                      eventsEnabled
                      position="bottom right"
                      style={{
                        marginTop: "-2px",
                        marginRight: "-0.55px",
                        padding: 0,
                        borderTopRightRadius: 0,
                        zIndex: 999,
                      }}
                      content={
                        <CogOptions
                          active={activeView}
                          center={{ ...center, user_id: service.user_id }}
                          service={{ ...center, user_id: service.user_id }}
                          setToDelete={setToDelete}
                          setToRestore={setToRestore}
                          setToCancel={setToCancel}
                          setToUpdate={setToUpdate}
                        />
                      }
                      hoverable
                      trigger={
                        <button
                          className={`btnFilter p-2 border-none my-1
                            ${
                              currentServiceId === center.center_id
                                ? "bg-hubBlack text-hubGrayLt2 rounded-bl-none rounded-br-none"
                                : "bg-hubGrayLt2 hover:bg-hubGrayDark2 hover:text-hubBlack"
                            }
                          `}
                        >
                          <FaCog />
                        </button>
                      }
                      onClose={() => setCurrentServiceId(undefined)}
                      onOpen={() => setCurrentServiceId(center.center_id)}
                    />
                  ))}
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  const { data: services, isFetching: isLoading } = useQuery(
    "services",
    usersApi.getSubSummary
  );
  const { data: servicesCount, isFetching: isLoadingServicesCount } = useQuery(
    "servicesCount",
    serviceApi.get
  );
  const { data: revenue, isFetching: isLoadingRevenue } = useQuery(
    "revenue",
    stripeApi.revenue
  );

  const resetFilters = () => {
    setCurrentPage(1);
    setServiceFilter("");
    setSubTypeFilter("");
    setFilterSelection("service");
    setColumnFilter("none");
    setFilterDir(false);
    setSubscriptionTypeList([]);
  };

  const turnPage = (pageNumber: number) => {
    setPage(pageNumber);
    setToggleFetch(!toggleFetch);
  };

  const softDeleteOptions = {
    onSuccess: () => {
      alertMessage("info", "Subscription deleted.");
      // queryClient.invalidateQueries("services");
      setToggleFetch(!toggleFetch);
    },
    onSettled: () => {
      setToDelete(undefined);
      setToUpdate(undefined);
    },
  };

  const { mutate: softDelete, isLoading: isLoadingSoftDelete } = useMutation(
    subscriptionsApi.softDelete,
    softDeleteOptions
  );

  const { mutate: softDeleteTrial, isLoading: isLoadingSoftDeleteTrial } =
    useMutation(subscriptionsApi.softDeleteTrial, softDeleteOptions);

  const hardDeleteOptions = {
    onSuccess: () => {
      alertMessage("info", "Subscription deleted.");
      setToggleFetch(!toggleFetch);
      // queryClient.invalidateQueries("services");
      queryClient.invalidateQueries("servicesCount");
    },
    onSettled: () => {
      setToDelete(undefined);
    },
  };

  const { mutate: hardDelete, isLoading: isLoadingHardDelete } = useMutation(
    subscriptionsApi.hardDelete,
    hardDeleteOptions
  );

  const { mutate: hardDeleteTrial, isLoading: isLoadingHardDeleteTrial } =
    useMutation(subscriptionsApi.hardDeleteTrial, hardDeleteOptions);

  const onConfirmDelete = async () => {
    if (!toDelete) return;

    const { userId, centerId, isTrial } = toDelete;
    const shouldSoftDelete = activeView;

    if (shouldSoftDelete) {
      isTrial
        ? softDeleteTrial({ centerId })
        : softDelete({ userId, centerId });
    } else {
      isTrial
        ? hardDeleteTrial({ centerId })
        : hardDelete({ userId, centerId });
    }
  };

  const onCancelTrial = async () => {
    if (!toUpdate) return;
    softDelete({
      userId: String(toUpdate?.userId),
      centerId: String(toUpdate?.centerId),
    });
  };

  const onConfirmCancel = async () => {
    toCancel && cancel(toCancel);
  };

  const { mutate: cancel, isLoading: isLoadingCancel } = useMutation(
    subscriptionsApi.cancel,
    {
      onSuccess: () => {
        alertMessage("info", "Subscription cancelled.");
        // queryClient.invalidateQueries("services");
        setToggleFetch(!toggleFetch);
        queryClient.invalidateQueries("revenue");
      },
      onSettled: () => {
        setToCancel(undefined);
      },
    }
  );

  const isDeleting = useMemo(
    () =>
      isLoadingSoftDelete ||
      isLoadingSoftDeleteTrial ||
      isLoadingHardDelete ||
      isLoadingHardDeleteTrial,
    [
      isLoadingSoftDelete,
      isLoadingSoftDeleteTrial,
      isLoadingHardDelete,
      isLoadingHardDeleteTrial,
    ]
  );

  const restoreOptions = {
    onSuccess: () => {
      alertMessage("info", "Subscription restored.");
      setToggleFetch(!toggleFetch);
      // queryClient.invalidateQueries("services");
    },
    onSettled: () => {
      setToRestore(undefined);
    },
  };

  const { mutate: restore, isLoading: isLoadingRestore } = useMutation(
    subscriptionsApi.restore,
    restoreOptions
  );

  const { mutate: restoreTrial, isLoading: isLoadingRestoreTrial } =
    useMutation(subscriptionsApi.restoreTrial, restoreOptions);

  const isRestoring = useMemo(
    () => isLoadingRestore || isLoadingRestoreTrial,
    [isLoadingRestore, isLoadingRestoreTrial]
  );

  const { mutate: manualUpdate, isLoading: isLoadingUpdate } = useMutation(
    subscriptionsApi.manualUpdateSub,
    {
      onSuccess: () => {
        alertMessage("info", "Subscription updated.");
        setToggleFetch(!toggleFetch);
      },
      onSettled: () => {
        setToUpdate(undefined);
      },
    }
  );

  const { mutate: manualCancel, isLoading: isLoadingManualCancel } =
    useMutation(subscriptionsApi.cancel, {
      onSuccess: () => {
        alertMessage("info", "Subscription cancelled.");
        setToggleFetch(!toggleFetch);
      },
      onSettled: () => {
        setToUpdate(undefined);
      },
    });

  const onConfirmRestore = async () => {
    if (!toRestore) return;

    const { userId, centerId, isTrial } = toRestore;
    isTrial ? restoreTrial({ centerId }) : restore({ userId, centerId });
  };

  const onConfirmManualUpdate = async () => {
    if (!toUpdate) return;
    const { userId, centerId, priceId, endDate, interval } = toUpdate;
    manualUpdate({
      user_id: Number(userId),
      center_id: Number(centerId),
      price_id: priceId,
      end_date: endDate,
      interval,
    });
  };

  const onConfirmManualCancelNow = async () => {
    if (!toUpdate) return;
    const { userId, centerId } = toUpdate;
    manualCancel({ userId, centerId, cancel_type: "now" });
  };

  const onConfirmManualCancelEOB = async () => {
    if (!toUpdate) return;
    const { userId, centerId } = toUpdate;
    manualCancel({ userId, centerId, cancel_type: "end_of_billing" });
  };

  return (
    <>
      {toDelete && (
        <Confirm
          isOpen={!!toDelete}
          size="tiny"
          confirmText={
            activeView ? "Delete subscription" : "Permanently delete "
          }
          declineText="Cancel"
          onConfirm={isDeleting ? undefined : onConfirmDelete}
          onDecline={isDeleting ? undefined : () => setToDelete(undefined)}
          onClose={() => setToDelete(undefined)}
        >
          <div className="flex flex-col items-center">
            <p className="text-lg pb-6 pt-2">
              {activeView
                ? "Are you sure you want to delete subscription?"
                : "Are you sure you want to permanently delete?"}
            </p>
            <p className="text-reg text-hubBlack text-opacity-50 leading-relaxed px-3 pb-10 text-center">
              {activeView
                ? `
              Deleted subscriptions will be held in “Deleted” tab until you
              restore or delete them permanently.`
                : "All subscription information will be permanently deleted from the HUB with no option to retrieve the information."}
            </p>
          </div>
          {isDeleting && (
            <div>
              <Spinner text="Deleting subscription..." />
            </div>
          )}
        </Confirm>
      )}

      {toRestore && (
        <Confirm
          isOpen={!!toRestore}
          size="tiny"
          confirmText="Restore subscription"
          declineText="Cancel"
          onConfirm={isRestoring ? undefined : onConfirmRestore}
          onDecline={isRestoring ? undefined : () => setToRestore(undefined)}
          onClose={() => setToRestore(undefined)}
          actionBtnsClass="blue"
        >
          <div className="flex flex-col items-center">
            <p className="text-lg pb-6 pt-2">
              This subscription will be restored
            </p>
            <p className="text-reg text-hubBlack text-opacity-50 leading-relaxed px-3 pb-10 text-center">
              This subscription will be visible again in the “Active” tab, and
              all information retored.
            </p>
          </div>
          {isRestoring && (
            <div>
              <Spinner text="Restoring subscription..." />
            </div>
          )}
        </Confirm>
      )}

      {toCancel && (
        <Confirm
          isOpen={!!toCancel}
          size="tiny"
          text="Are you sure you want to cancel subscription?"
          onConfirm={isLoadingCancel ? undefined : onConfirmCancel}
          onDecline={isLoadingCancel ? undefined : () => setToCancel(undefined)}
          onClose={() => setToCancel(undefined)}
        >
          {isLoadingCancel && (
            <div>
              <Spinner text="Cancelling subscription..." />
            </div>
          )}
        </Confirm>
      )}
      {toUpdate && (
        <UpdateSubscription
          isManual={
            (toUpdate.quantity === 0 && toUpdate.product !== "trial") ||
            toUpdate.product === "trial"
          }
          data={toUpdate}
          setDataToUpdate={(data) => {
            setToUpdate(data);
          }}
          isOpen={!!toUpdate}
          isLoadingUpdate={
            isLoadingUpdate || isLoadingSoftDelete || isLoadingManualCancel
          }
          onUpdateConfirm={isLoadingUpdate ? undefined : onConfirmManualUpdate}
          onCancelTrial={onCancelTrial}
          onCancelSubNow={
            isLoadingManualCancel ? undefined : onConfirmManualCancelNow
          }
          onCancelEndOfBillingCycle={
            isLoadingManualCancel ? undefined : onConfirmManualCancelEOB
          }
          onClose={() => setToUpdate(undefined)}
        />
      )}
      <div
        className={`${styles.mainContainer} flex-1 px-2 mt-10 md:mb-28 lg:mb-10`}
      >
        <div className="flex">
          <div className={styles.headerTextIcon}>
            <FaUsers size={55} />
          </div>
          <div className={`${styles.headerText} ml-4`}>
            <h1>Subscriptions</h1>
            <p>Summary of current subscribers</p>
          </div>
        </div>
        <div className={`${styles.container} md:px-7 lg:px-10 py-10 mt-9`}>
          <Summary
            isLoadingSubscribers={isLoading}
            // totalSubscribers={activeView ? services?.total : services?.totalSoft}
            // payingSubscribers={
            //   activeView ? services?.paying : services?.payingSoft
            // }
            // trialSubscribers={activeView ? services?.trial : services?.trialSoft}
            totalSubscribers={services?.total_subscribers}
            payingSubscribers={services?.total_paying_subscribers}
            trialSubscribers={services?.total_trial_subscribers}
            isLoadingCount={isLoadingServicesCount}
            qipUsers={servicesCount?.admin}
            portalUsers={servicesCount?.member}
            totalUsers={servicesCount?.total}
            isLoadingRevenue={isLoadingRevenue}
            revenueMonthly={revenue?.sum_of_monthly}
            revenueAnnual={revenue?.sum_of_yearly}
          />
          <div className="grid gap-y-10">
            <div>
              <button className={`btnGreen`}>
                <FaUserShield className="mr-2 mb-0.5" size={19} /> Services &
                Admins
              </button>
            </div>
            <Filters
              setToggleSearch={() => {
                setOrder("asc");
                setToggleFetch(!toggleFetch);
              }} 
              isLoading={loading}
              filterSelection={filterSelection}
              setFilterSelection={(filter) => {
                setFilterSelection(filter);
                if (subTypeFilter) {
                  setOrder("asc");
                  setToggleFetch(!toggleFetch);
                }else if (serviceFilter) {
                  setOrder("asc");
                  setToggleFetch(!toggleFetch);
                }
              }}
              active={activeView}
              deleted={!activeView}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              serviceFilter={serviceFilter}
              productList={productList}
              subTypeFilter={subTypeFilter}
              setServiceFilter={(search) => {
                console.log("sf ", search);
                setServiceFilter(search);
              }}
              setSubTypeFilter={(search) => {
                if(search == "All"){
                  resetFilters();
                }else{
                  setSubTypeFilter(search);
                  setSearchBy('subscription')
                }
                
              }}
              setActive={() => {
                resetFilters();
                setActiveView(true);
              }}
              setDeleted={() => {
                resetFilters();
                setActiveView(false);
              }}
              // deletedCount={services ? services.softDeletedCount : 0}
              deletedCount={0}
            />
            <Table
              active={activeView}
              setLoading={setLoading}
              header={<Header />}
              content={<Content />}
              page={page}
              changePage={(page) => {
                turnPage(page);
              }}
              orderBy={orderBy}
              order={order}
              searchBy={searchBy}
              search={subTypeFilter != "" ? subTypeFilter : serviceFilter}
              toggleFetch={toggleFetch}
              getData={setData}
              getSubscriptionTypeList={setSubscriptionTypeList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptionsv2;
