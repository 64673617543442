import { axiosQip as axios } from "../services/axios";
import { RECEIVER_TYPE } from "../pages/Messages/Compose";

export type Message = {
  content: string;
  date: string;
  id: number;
  is_draft: 1 | 0;
  option?: number;
  sender: string;
  subject: string;
  user_id?: number[];
  user_to?: string;
  sent_to?: string[];
  send_to?: string[];
};

export type MsgUser = {
  id: number;
  name: string;
  user_type?: string;
};

type MessageParams = {
  subject: string;
  content: string;
  is_draft: number;
};

async function get(): Promise<Message[]> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(
    `/api/message-getallusers-superadmin`,
    config
  );

  return response.data.sort((a: any, b: any) => {
    return b.id - a.id;
  });
}

async function deleteMsg(messageId: number): Promise<boolean> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.delete(
    `/api/message-delete-superadmin/${messageId}`,
    config
  );
  return response?.status === 200;
}

async function getAllUsers(): Promise<MsgUser[]> {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(`/api/message-getallusers`, config);
  return response?.data;
}

async function messageAdmins(payload: MessageParams) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(`/api/message-admins`, payload, config);
  return response.status === 200;
}

async function messageAll(payload: MessageParams) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(`/api/message-all-users`, payload, config);
  return response.status === 200;
}

async function messageServices(
  payload: MessageParams & { center_id: number[] }
) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `/api/message-users-to-selected-center`,
    payload,
    config
  );
  return response.status === 200;
}

async function messageUsers(payload: MessageParams & { user_to: number[] }) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `/api/message-selected-user`,
    payload,
    config
  );
  return response.status === 200;
}

export async function updateMessage({
  id,
  message,
}: {
  id: number;
  message: MessageParams & {
    options: RECEIVER_TYPE;
    ids: number[];
  };
}) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const { subject, content, is_draft, options, ids } = message;

  const recipients =
    options === RECEIVER_TYPE.centers
      ? { center_id: ids }
      : options === RECEIVER_TYPE.user
      ? { user_to: ids }
      : {};

  const payload = {
    subject,
    content,
    is_draft,
    options,
    ...recipients,
  };

  const response = await axios.put(
    `/api/message-update/${id}`,
    payload,
    config
  );
  return response.status === 200;
}

export const messagesApi = {
  get,
  deleteMsg,
  getAllUsers,
  messageAdmins,
  messageAll,
  messageServices,
  messageUsers,
  updateMessage,
};
