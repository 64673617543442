import generateRandom from "../utils/generateRandom";

export const redirect = (
  userId: number,
  stripeId: string,
  centerId?: number
) => {
  const hostname = new URL(window.location.href).hostname.split(".");

  let token = localStorage.getItem("hubAdminToken");

  // const sub = new URL(process.env.REACT_APP_QIP_URL || "").hostname.split(
  //   "."
  // )[0];

  let appUrl = process.env.REACT_APP_ADMIN_QIP_URL;

  if (hostname[0] === "localhost") {
    window.open(
      "http://localhost:3001/view-hub-admin-service?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        stripeId +
        "&accessToken=" +
        token +
        "&counter=" +
        generateRandom(12)
    );
  } else {
    // const host = hostname.slice(1, hostname.length).join(".");
    window.open(
      appUrl +
        "view-hub-admin-service?userId=" +
        userId +
        "&centerId=" +
        centerId +
        "&stripeId=" +
        stripeId +
        "&accessToken=" +
        token +
        "&counter=" +
        generateRandom(12)
    );
  }
};
