import { createContext, useContext } from "react";

export enum Routes {
  HOMEPAGE = "homepage",
  SUBSCRIPTIONS = "subscriptions",
  LOGINPAGE = "loginpage",
  APPUSAGE = "appusage",
  USERS = "users",
  MESSAGES = "messages"
}

export type RoutesType = typeof Routes[keyof typeof Routes];

export type AppContextType = {
  route: Routes;
  authed: boolean;
};

export const AppContext = createContext<AppContextType>({
  route: Routes.HOMEPAGE,
  authed: false,
});

export const useApp = () => useContext(AppContext);
