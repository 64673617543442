import { useQuery } from "react-query";
import { serviceApi } from "../api/service";
import { STALE_TIME } from "../services/query";

const useFetchServices = (enabled = false) => {
  return useQuery(["services"], () => serviceApi.getCenters(), {
    enabled,
    staleTime: STALE_TIME.HIGH,
  });
};

export default useFetchServices;
