// import styles from "./SendingMessage.module.css";

import { useEffect, useState } from "react";
import Confirm from "../Confirm";

type SendingMessageProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function SendingMessage(props: SendingMessageProps) {
  const { isOpen, onClose, onConfirm } = props;
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (timeLeft === 0) {
      onConfirm();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, onConfirm]);

  return (
    <Confirm size="small" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center">
        <p className="text-2xl pb-6 pt-2">
          Your message will send in 60 seconds.
        </p>
        <p className="text-reg font-light">Click to cancel, or send now.</p>
        <p className="text-5xl mt-4">{timeLeft}</p>
        <div className="flex gap-4 my-6">
          <button
            className="btnBlueLt px-12 py-4 text-lg font-medium"
            onClick={onConfirm}
          >
            Send
          </button>
          <button
            className="btnRed px-10 py-4 text-lg font-medium"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Confirm>
  );
}
