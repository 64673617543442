import { axiosQip as axios } from "../services/axios";

type CommonParams = {
  userId: string;
  centerId: string;
  cancel_type?: "now" | "end_of_billing";
};

async function cancel({ userId, centerId, cancel_type }: CommonParams) {
  const payload = {
    user_id: Number(userId),
    center_id: Number(centerId),
    cancel_type: cancel_type ? cancel_type : null,
  };
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.post(`/api/cancelsubscription`, payload, config);
  return response.status === 200;
}

async function softDelete({ userId, centerId }: CommonParams) {
  const payload = {
    user_id: Number(userId),
    center_id: Number(centerId),
  };
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `/api/subscription-remove`,
    payload,
    config
  );
  return response.status === 200;
}

async function softDeleteTrial({ centerId }: Pick<CommonParams, "centerId">) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(
    `/api/center-sd-trialcenter/${centerId}`,
    config
  );
  return response.status === 200;
}

async function hardDelete({ userId, centerId }: CommonParams) {
  const payload = {
    user_id: Number(userId),
    center_id: Number(centerId),
  };
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `/api/delete-sd-subscription`,
    payload,
    config
  );
  return response.status === 200;
}

async function hardDeleteTrial({ centerId }: Pick<CommonParams, "centerId">) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(
    `/api/center-hd-trialcenter/${centerId}`,
    config
  );
  return response.status === 200;
}

async function restore({ userId, centerId }: CommonParams) {
  const payload = {
    user_id: Number(userId),
    center_id: Number(centerId),
  };
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.post(
    `/api/subscription-restore`,
    payload,
    config
  );
  return response.status === 200;
}

async function restoreTrial({ centerId }: Pick<CommonParams, "centerId">) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const response = await axios.get(
    `/api/center-sd-restore/${centerId}`,
    config
  );
  return response.status === 200;
}

type updateParams = {
  user_id: number;
  center_id: number;
  price_id?: string | null;
  end_date: string;
  interval?: string;
};

async function manualUpdateSub({
  user_id,
  center_id,
  price_id,
  end_date,
  interval,
}: updateParams) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  let payload = {
    user_id,
    center_id,
    price_id,
    end_date,
    interval,
  };

  const response = await axios.post(
    `/api/stripe/manual/update`,
    payload,
    config
  );
  return response.status === 200;
}

async function manualCancelSub(subscriptionId?: string | null) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  let payload = {
    subscriptionId,
  };

  const response = await axios.post(
    `/api/stripe/subscription/cancel`,
    payload,
    config
  );
}

type getSub = {
  page: number;
  orderBy: string;
  order: string;
  searchBy: string;
  search: string;
};

async function getSubscription({
  page,
  orderBy,
  order,
  searchBy,
  search,
}: getSub) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(`/api/centers/admin/users`, {
    headers: { ...config.headers },
    params: search
      ? { page, searchBy, search, order, orderBy }
      : { page, orderBy, order },
  });

  return response.data;
}

async function getSoftSubscription({
  page,
  orderBy,
  order,
  searchBy,
  search,
}: getSub) {
  let token: any = localStorage.getItem("hubAdminToken");

  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await axios.get(`/api/centers/admin/users/deleted`, {
    headers: { ...config.headers },
    params: search
      ? { page, searchBy, search, order, orderBy }
      : { page, orderBy, order },
  });

  return response.data;
}

export const subscriptionsApi = {
  cancel,
  softDelete,
  softDeleteTrial,
  hardDelete,
  hardDeleteTrial,
  restore,
  restoreTrial,
  getSubscription,
  getSoftSubscription,
  manualUpdateSub,
  manualCancelSub,
};
