import { useState } from "react";
import { FaCommentDots } from "react-icons/fa";
import Back from "../../components/Back";
import Confirm from "../../components/Popups/Confirm";
import styles from "./Users.module.css";

const Edit = () => {
  const [openPassword, setOpenPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  return (
    <>
      {openPassword && (
        <Confirm
          isOpen={openPassword}
          size="tiny"
          text="Create a new password"
          onClose={() => setOpenPassword(false)}
        >
          <div className="grid grid-cols-2 w-full px-4 gap-x-4">
            <div className="flex flex-col gap-y-4">
              <label>Enter new password</label>
              <input
                className="outline-none border border-hubBlack p-4 rounded"
                type="text"
              />
            </div>
            <div className="flex flex-col gap-y-4">
              <label>Repeat new password</label>
              <input
                className="outline-none border border-hubBlack p-4 rounded"
                type="text"
              />
            </div>
          </div>
          <button
            className="btnBlueLt px-14 py-4 mt-8 text-base"
            onClick={() => {
              setOpenPassword(false);
              setResetSuccess(true);
            }}
          >
            Save password
          </button>
        </Confirm>
      )}
      {resetSuccess && (
        <Confirm
          isOpen={resetSuccess}
          size="tiny"
          text="User recieved username & temporary password on their email"
          onClose={() => setResetSuccess(false)}
        >
          <button
            className="btnBlueLt px-16 py-4 text-lg"
            onClick={() => setResetSuccess(false)}
          >
            Ok
          </button>
        </Confirm>
      )}
      <div
        className={`${styles.mainContainer} flex-1 px-2 mt-10 md:mb-28 lg:mb-10`}
      >
        <div className="flex justify-between">
          <div className="flex">
            <div className={styles.headerTextIcon}>
              <FaCommentDots size={55} />
            </div>
            <div className={`${styles.headerText} ml-4 flex items-center`}>
              <h1>Edit user details</h1>
            </div>
          </div>
          <div className="flex items-center">
            <Back />
          </div>
        </div>
        <div
          className={`${styles.container} md:px-7 md:py-20 lg:px-10 lg:py-20 mt-10`}
        >
          <div className="max-w-6xl m-auto">
            <p className="text-lg text-center">Update user details</p>
            <div className="mt-10">
              <div className="flex flex-col gap-y-4">
                <label>
                  User’s email address<span className="text-red-400">*</span>
                </label>
                <input
                  className="outline-none border border-hubBlack p-4 rounded"
                  type="text"
                />
              </div>
              <div className="mt-10 grid grid-cols-2 gap-x-6">
                <div className="flex flex-col gap-y-4">
                  <label>
                    First name<span className="text-red-400">*</span>
                  </label>
                  <input
                    className="outline-none border border-hubBlack p-4 rounded"
                    type="text"
                  />
                </div>

                <div className="flex flex-col gap-y-4">
                  <label>
                    Last name<span className="text-red-400">*</span>
                  </label>
                  <input
                    className="outline-none border border-hubBlack p-4 rounded"
                    type="text"
                  />
                </div>
              </div>
              <div className="mt-8 flex justify-center gap-x-4">
                <button className="btnBlueLt px-16 py-5">Save changes</button>
                <button
                  className="btnGreenLt px-16 py-5"
                  onClick={() => setOpenPassword(true)}
                >
                  New password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
