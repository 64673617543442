import { IAuthUser } from "../types";

export const setUserToken = (token: string, callback: any) => {
  localStorage.setItem("hubAdminToken", token);
  callback();
};

export const isUserLoggedIn = () => localStorage.getItem("hubAdminToken");

export const getUserLoggedIn = (): IAuthUser => {
  const currentAuthUser = localStorage.getItem("authUser") || "{}";
  return JSON.parse(currentAuthUser);
};

export const userLogout = (callback: any) => {
  localStorage.clear();
  localStorage.removeItem("hubAdminToken");

  callback();
};
