import React, { Suspense } from "react";
import { FaSpinner } from "react-icons/fa";
import Header from "../Header";
import Footer from "../Footer";
import { RoutesType } from "../../context/App";

interface LayoutProps {
  Children: React.ElementType;
  route: RoutesType;
}

const Layout = (props: LayoutProps) => {
  const { Children } = props;
  return (
    <>
      <Header />
      <Suspense
        fallback={
          <div className="w-screen h-screen flex flex-col justify-center items-center">
            <FaSpinner className={`text-6xl text-green-300 animate-spin`} />
            <span className="mt-2">Loading...</span>
          </div>
        }
      >
        <div className="content-page">
          <Children />
        </div>
      </Suspense>
      <Footer />
    </>
  );
};

export default Layout;
